import { Button, Form, Modal, Divider } from "antd";
import { Content } from "antd/lib/layout/layout";
import { getRoles } from "../../actions/role";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { renderDate, renderInput, renderPasswordField, renderSelect, renderTextField } from "../../components/form";
import history from "../../history";


const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 12 },
};

const CreateSubOrganization = ({ title, visible, cancel,refresh, ok, api ,getRoles}) => {
  const [roles, setRoles] = useState(null)
  const [form] = Form.useForm();
  useEffect(async() => {
    window.scrollTo(0, 0)
    const res = await getRoles();
   
    setRoles(res)
  }, [])
 
  const onFinish = async (values) => {
    history.push("/done")
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };


  const createRoles = (label,value) => {
    return {label,value}
  }

  let option = [];
      option = (
        roles ? roles?.map(item => 
          createRoles(item.name , item.role_id ))
      : null)

  const MODAL = (
    <Modal
      title={title}
      centered
      visible={visible}
      width={800}
      onCancel={cancel}
      cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        {...layout}
        name="basic"
        
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete={false}
        requiredMark={false}
      >
        {renderInput("user_id", "নাম", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}
        {renderInput("name", "ঠিকানা", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}
        
        {renderInput("mobile_number", "মোবাইল নাম্বার", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
          { pattern: /^(01)[0-9][0-9]{8}$/, message: "দয়া করে সঠিক মোবাইল নম্বরটি দেন!" },
        ])}
        {renderInput("email", "ইমেইল", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}
        {renderInput("permanent_address", "মালিকদের ইক্যুইটি", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}
        {renderInput("dd", "সম্পদের মান", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}
        {renderInput("dddd", "ইনভেন্টরি মান", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}
        {renderInput("ppp", "সম্পদের মান", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}
        {renderInput("ddd", "হাতে নগদ", [
        //   { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])}

        

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  
  return(
    <Content>
    {MODAL}
    </Content>
  );
};

export default connect(null,{getRoles})(CreateSubOrganization);
