import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";


export const operationHelper = () => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    
    let response = null;
   
    
      response = await api(localStorage.getItem("token")).get(`/operation/helper`);
     
    
   
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getTicket = (p,s,id,a,ds,de) => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    
    let response = null;
    response = await api(localStorage.getItem("token")).get(`/operation/ticket?p=${p?p:1}&id=${id?id:""}&s=${s?s:""}&a=${a?a:""}&ds=${ds?ds:""}&de=${de?de:""}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getTicketDetails = (ticket) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`/operation/ticket/detail?ticket=${ticket}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data.ticket;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const TicketCreate = (data,ticket) => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    const obj = {
      subject : data?.subject,
      account : data?.account,
      message : data.message,
    }
    const response = await api(localStorage.getItem("token")).post('/operation/ticket/create', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Reply is successfully send!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const ticketReply = (ticket, data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    const obj = {
      ticket : ticket,
      message : data.message,
    }
    const response = await api(localStorage.getItem("token")).patch('operation/ticket/reply', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Reply is successfully send!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};


export const closeTicket = (ticket,remark) => async (dispatch) => {
  dispatch({ type: LOADING });
   
  try{
  
    const obj = {
        ticket : ticket,
        remark : remark.remark,
    }
    const response = await api(localStorage.getItem("token")).patch('message-management/ticket/closed', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Reply is successfully closed!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const SendNotification = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    
    const obj = {
        email : data.email,
        message : data.message,
    }
    
    const response = await api(localStorage.getItem("token")).post('message-management/notification/send', {...obj});
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Email successfully send!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};