import React from "react";
import { Form, Input, Button, Layout, Col,Checkbox } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Logo from "./logo.png";
import { login } from "../actions/auth";
import { connect } from "react-redux";
import './index.css'
import history from "../history";
const { Footer } = Layout;

const Root = styled.div`
  position: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Card = styled.div`
  
  width: 40%;
  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;
const Login = ({login}) => {
  const onFinish = (values) => {
    login(values)
  };
  const ROOT=()=>{
    return( <Root>
      <Card>
        <Col
          span={24}
          style={{
            alignSelf: "center",
            borderRadius: "10px",
            marginTop:"80px",
            height: "400px",
            backgroundColor: "#fff",
            padding: "20px",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <img src={Logo} alt="logo" style={{ width: "100px", alignSelf: "center" }} />
          <h2 style={{ textAlign: "center", fontSize:'12px', margin: '3px' }}>আপনার একাউন্টে লগইন করুন</h2>
          <Form
            style={{
              alignSelf: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
              style={{ alignSelf: "center", width: "70%" }}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                
                placeholder="ইমেইল"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
              style={{ alignSelf: "center", width: "70%" }}

            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="পাসওয়ার্ড"
                size="large"
              />
            </Form.Item>
            {/* <Form.Item
            name="remember"
            valuePropName="checked"
            style={{ alignSelf: "center", width: "70%" }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

            <Form.Item
            style={{ alignSelf: "center", width: "70%" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ alignSelf: "center", width: "100%" }}
                className="login-form-button"
                
              >

                সাইন ইন করুন
              </Button>
              
              <a className="author" href="/privacy-policy" target="_blank">Privacy & policy</a>
            </Form.Item>
          </Form>
          
        <br />
        </Col>
      </Card>
      
      <div className="imageG">
        <img src="./sw.png" alt="" className="bimg" /> &nbsp; &nbsp; &nbsp;
        <img src="./marico.png" alt="" className="mimg" /> &nbsp; &nbsp; &nbsp;
        <img src="./undp.png" alt="" className="undpimg" />
      </div>
    </Root>)
  }
 
  return (
   
      
     ROOT()

  );
};

export default connect(null,{login})(Login);
