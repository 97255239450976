import { Button, Card } from "antd";
import React from "react";

import styled from "styled-components";
import history from "../../history";
import { SubTitle } from "../../Style";

const Bar = styled.div`
  position: absotute;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding: 5px;
    img {
      width: 70px;
    }
    h1 {
      font-size: 15px;
    }
  }
`;

const Setting = () => {
  return (
    <>
      {/* <Bar>
        <div className="logo">
          <a href="https://bahonexpress.com/" target="_blank" rel="noreferrer">
            <img src="/logo.png" alt="bahonLogo" width="120" height="50" />
          </a>
        </div>

        <div className="tracker">
          <h1 style={{ color: "#635f5e" }}>Internet lost</h1>
        </div>
      </Bar> */}
   
      <Card hoverable  style={{ margin: 'auto',width: '90%',textAlign: 'center',marginTop: '15%'}}
        // onClick={() => { history.push('/moneywithdraw')}}
        >
          <SubTitle >ইউজার ম্যানেজমেন্ট </SubTitle>
           
           
          </Card>
     
      

      {/* <div style={{ textAlign: "center", marginTop: "150px" }}>
        {" "}
        <img src="/done.png" alt="img" 
       width="100" />{" "}
      </div>

      <h1 style={{ textAlign: "center", fontSize: "24px" }}>
      সফলভাবে জমা দেওয়া হয়েছে
      </h1>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Button 
    //    onBack={() => window.history.back()}
      onClick={() => history.push("/dashboard2")}
      >
        
        পিছনে

       
      </Button>
      </div> */}
    </>
  );
};

export default Setting;
