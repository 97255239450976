import { Button } from "antd";
import React from "react";

import styled from "styled-components";
import history from "../history";

const Bar = styled.div`
  position: absotute;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding: 5px;
    img {
      width: 70px;
    }
    h1 {
      font-size: 15px;
    }
  }
`;

const NetworkError = () => {
  return (
    <>
      <Bar>
        <div className="logo">
          <a href="" target="_blank" rel="noreferrer">
            {/* <img src="/logo.png" alt="bahonLogo" width="120" height="50" /> */}
          </a>
        </div>

        <div className="tracker">
          {/* <h1 style={{ color: "#635f5e" }}>Internet lost</h1> */}
        </div>
      </Bar>
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        {" "}
        <img src="/nowifi.png" alt="img" 
       width="150" />{" "}
      </div>
      <br />
      <h4 style={{ textAlign: "center", fontSize: "14px" }}>
          Unable to connect to the Internet or something went wrong.
      </h4>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Button onClick={() => history.goBack()}>
        Please try again
      </Button>
      </div>
    </>
  );
};

export default NetworkError;
