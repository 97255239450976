import React, { Component } from "react";
import styled from 'styled-components';
import { resetMessage } from "../actions/reset";
import { message } from 'antd';
import { connect } from "react-redux";

const defaultTime = 3000;
class MessageAlert extends Component {
  state = {
    show: false,
    message: null,
  };
  handleShow = () => {
    this?.setState({ show: !this.state.show });
  };
componentDidMount() {
  // use for Redux clear
    setTimeout( () =>{ this?.props?.resetMessage()}, this.props?.duration ? this.props?.duration : defaultTime );
  } 

  render() {
    const { type } = this?.props;
  
    return (
      type === 'error' ? message.error(this?.props?.value, this.props?.duration ? this.props?.duration/1000 : defaultTime/1000 ) : message.success(this?.props?.value, this.props?.duration ? this.props?.duration/1000 : defaultTime/1000)
    );
  }
}

export default connect(null, { resetMessage })(MessageAlert);
