import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col,Card,Button, Input, Table, Breadcrumb, DatePicker, Pagination, Modal } from "antd";
import {
  LikeOutlined,InfoCircleOutlined, SearchOutlined, HomeOutlined, RollbackOutlined, DeleteOutlined
 
} from "@ant-design/icons";
import history from "../../history";
import { connect } from "react-redux";
import { CardTitle, SubTitle, TitleH1, TitleN } from "../../Style";

import { getDeposits } from "../../actions/deposite";
import DepositeMoney from "./depositeMoney";
import DepositeDetails from "./depositeDetails";
import { da } from "date-fns/locale";

const { RangePicker } = DatePicker;

const showTotal = (total) => `Total ${total} items`;

const DepositeList = ({ location, getDeposits }) => {

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [account, setAccount] = useState([]);
  const [page, setPage] = useState(1);
  const [tID, setTID] = useState(null);
  const [ds, setDs] = useState('');
  const [de, setDe] = useState(''); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detail, setDetail] = useState(false);
  const showModal = (data) => {
    setDetail(data)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  useEffect(async () => {
    window.scrollTo(0, 0)
    const id = new URLSearchParams(location.search).get("account");
    setAccount(id);
    const res = await getDeposits(page, id, 'Deposit', tID, ds, de);
    setData(res?.list);
    setCount(res?.count);
  }, []);
  
  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };
  const columns = [
    {
      title: "আইডি",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "30%",
    },
    {
      title: "তারিখ",
      dataIndex: "date",
      key: "date",
      width: "40%",
    },
    {
      title: "বিস্তারিত",
      dataIndex: "action",
      key: "action",
      width: "15%",
    },
  ];
  const createData = (
    transaction_id,
    date,
    action
  ) => {
    return { transaction_id, date, action };
  };
  const rows = [
    data
      ? data?.map((item, key) =>
          createData(
            item?.transaction_id,
            item?.created_at ? makeLocalTime(item?.created_at) : '',
            <Button
              size="small"
              type="primary"
              style={{backgroundColor:'#019fb8'}}
              // onClick={() => history.push(`/deposite-details?.account=${account}`)}
              onClick={() => showModal(item?.transaction_detail)}
            >
              বিস্তারিত
            </Button>
          )
        )
      : "",
  ];

  const handleApiResetSearch = async () => {
    setPage(1);
    setTID('');
    setDs('');
    setDe('');
    const res = await getDeposits(1, account, '', '', '', '');
    setData(res?.list);
  };

  const handleDate = (date) => {
    if (date) {
      const ds = new Date(date[0]?.$d);
      const de = new Date(date[1].$d);
      setDs((ds.toISOString()).split('T')[0]);
      setDe(de.toISOString().split('T')[0])
    } else {
      setDs(null);
      setDe(null)
      return;
    }
  };

  const handleApiSearch = async () => {
    const res = await await getDeposits(page, account, 'Deposit', tID, ds, de);
    setData(res?.list);
  };

  const handlePagination = async (e) => {
    setPage(e);
    const res = await await getDeposits(e, account, 'Deposit', tID, ds, de);
    setData(res?.list);
  };

  return (
    <Content>  

      <Breadcrumb
        items={[
          {
            title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
          },
          {
            title: 'টাকা জমা',
          },
          {
            title: (<RollbackOutlined onClick={() => history.goBack()} />),
          },
        ]}
      />
      <Row gutter={10}>
        <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
            <Input value={tID} size="middle" placeholder="লেনদেন আইডি" allowClear style={{width:'99%'}} onChange={(e)=>setTID(e.target.value)} />
        </Col>
        

        <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
              <RangePicker
                onChange={handleDate}
                format="YYYY/MM/DD"
                style={{ width: "99%" }}
              />
        </Col>
       
        <Button  style={{margin: '5px 5px'}} size="middle" type="primary" onClick={handleApiSearch} icon={<SearchOutlined />}>খুজুন </Button>
        <Button  style={{margin: '5px 5px'}} size="middle" type="primary" danger onClick={handleApiResetSearch} icon={<DeleteOutlined />}>ফিল্টার মুছুন</Button>
        {/* <Button style={{margin: '5px 5px'}} size="middle" type="primary" icon={<PlusCircleFilled />} onClick = { showModal }>নতুন তৈরি</Button> */}
      </Row>  
      <br/>
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: false, y: 300}}
        sticky
        size="small"
        tableLayout='auto' 
        pagination={false}
      />
      <br />
      <Pagination showTotal={showTotal} current={page} pageSize="20" total={count} onChange={(e) => { handlePagination(e); }} />
      <br />
      <Row gutter={20}>
        <Col xs={24} lg={24} md={24} sm={24} style={{ marginTop: "10px" }}>
          <Button
            key="1"
            type="primary"
            style={{width:'100%',}}
            onClick={() => history.push(`/deposite-money?id=${account}`)}
          >
            নতুন জমা করুন
          </Button>
        </Col>
      </Row>
      <Modal width={800} title="জমার বিবরণ" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <DepositeDetails data={detail} />
      </Modal>
    </Content>
   
  );
};

export default connect(null, {
    getDeposits
  })(DepositeList);
  
