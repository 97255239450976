import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Button, Table, Space, Input, Breadcrumb } from "antd";
import { SearchOutlined, CustomerServiceOutlined, HomeOutlined, RollbackOutlined } from "@ant-design/icons";
import history from "../../history";

import {  productList } from "../../actions/operation";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import ForwardFloatButton from "antd/es/float-button/FloatButton";

const Search = Input.Search;

const ProductList = ({ location, productList }) => {
  const [products, setProducts] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);

  
  useEffect( async () => {
    window.scrollTo(0, 0)
    const id = new URLSearchParams(location.search).get("id");
    const res =  await productList(id);
    if(res?.success) {
      setProducts(res?.product);
    }
  }, []);


  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (name, production_cost, sale_price) => {
    return {name, production_cost, sale_price};
  };

  const columns = [
    {
      title: 'নাম',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      align: 'center',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'উৎপাদন খরচ',
      dataIndex: 'production_cost',
      key: 'production_cost',
      width: '20%',
      align: 'center',
    },
    {
      title: 'বিক্রয় মূল্য',
      dataIndex: 'sale_price',
      key: 'sale_price',
      width: '15%',
      align: 'center',
    },
    // {
    //   title: 'স্টেটাস',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '20%',
    //   align: 'center',
    // },
    // {
    //   title: 'একশন',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: '20%',
    //   align: 'center',
    // },
  ];

  const rows = [
    products
      ? products?.map((item, key) => createData(
        item?.name, 
        item?.production_cost, 
        item?.sale_price, 
        // item?.active ? <Tag color="#f50">চলমান</Tag> : <Tag color="#27ae60">সমাধান হয়েছে</Tag>, 
        <>
          {/* <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleView(item?.id)}>বিস্তারিত দেখুন</Button> &nbsp; */}
         
        </>
        ))
      : "",
  ];
  return (
  <Content> 
    <Breadcrumb
        items={[
          {
            title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
          },
          {
            title: (
              <>
                <span>প্রোডাক্ট সমূহ</span>
              </>
            ),
          },
          {
            title: (<RollbackOutlined onClick={() => history.goBack()} />),
          },
        ]}
      />
    {products ? 
      <Table size="small" columns={columns} dataSource={rows[0]} sticky pagination={false}/>
    : null}
        
      
      <ForwardFloatButton
        onClick={() => history.push('/tickets')}
        shape="square"
        danger
        type="primary"
        style={{
          right: 24,
          bottom: 20,
        }}
        icon={<CustomerServiceOutlined />}
      />
    </Content>
   
  );
};

export default connect(null, { productList })(ProductList);
