import React from 'react'
import { Form, InputNumber } from 'antd';
import { TbCurrencyTaka } from "react-icons/tb";
const NumberInputBox = ({label,name, value, rules, placeholder, type, ...rest}) => {
    return ( 
       
        <Form.Item
            label={label}
            name={name}
            rules={rules}
            
            >
            <InputNumber name={name} min={0} placeholder={placeholder} type='number'  value={value} {...rest} addonAfter={<TbCurrencyTaka />} />
        </Form.Item>
     );
}
 
export default NumberInputBox;