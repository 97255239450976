import { Button, Form, Row, Col, Breadcrumb, Input, InputNumber, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import {  withdrawCreate, removeFile, uploadFile } from "../../actions/withdraw";
import { numberInput, renderTextField } from "../../components/form";
import history from "../../history";
import Resizer from 'react-image-file-resizer';
import { HomeOutlined, RollbackOutlined, UploadOutlined } from "@ant-design/icons";
import './style.css';
import { FcEmptyTrash } from "react-icons/fc";
import { TbCurrencyTaka } from "react-icons/tb";
const { Option } = Select;


const WithdrawMoney = ({ removeFile, withdrawCreate, uploadFile, location }) => {
  const [account, setAccount] = useState([]);
  const [purchaseMaterialFl, setPurchaseMaterialFl] = useState(null);
  const [otherMaterialFl, setOtherMaterialFl] = useState(null);
  const [payableFl, setPayableFl] = useState(null);
  const [mfsFl, setMfsFl] = useState(null);
  const [cashFl, setCashFl] = useState(null);
  const [assetFl, setAssetFl] = useState(null);
  const [bankFl, setBankFl] = useState(null);
  const [salaryFl, setSalaryFl] = useState(null);
  const [bonusFl, setBonusFl] = useState(null);
  const [wagesFl, setWagesFl] = useState(null);
  const [transportFl, setTransportFl] = useState(null);
  const [utilityFl, setUtilityFl] = useState(null);
  const [entertainmentFl, setEntertainmentFl] = useState(null);
  const [communicationFl, setCommunicationFl] = useState(null);
  const [advertisementFl, setAdvertisementFl] = useState(null);
  const [marketingFl, setMarketingFl] = useState(null);
  const [sampleFl, setSampleFl] = useState(null);
  const [intraTransferFl, setIntraTransferFl] = useState(null);
  const [intraTransfer, setIntraTransfer] = useState(0);
  const [creditAmountFl, setCreditAmountFl] = useState(null);
  
  const [totalDeposte, setTotalDeposte] = useState({
    purchase_material: 0,
    received_from_sale: 0,
    other_material: 0,
    credit_amount: 0,
    payable: 0,
    asset: 0,
    salary: 0,
    bonus: 0,
    wages: 0,
    transport: 0,
    utility: 0,
    entertainment: 0,
    communication: 0,
    advertisement: 0,
    marketing: 0,
    sample: 0,
  });
  const [totalEntry, setTotalEntry] = useState({
    cash: 0,
    mfs: 0,
    bank: 0,
  });
  const [total,setTotal]=useState(0);
  const [rest,setRest]=useState(0);

  const [form] = Form.useForm();
  useEffect(async() => {
    window.scrollTo(0, 0)
    const id = new URLSearchParams(location.search).get("id");
    setAccount(id);
    form.setFieldsValue({
      // sale: 0,
      // received_from_sale: 0,
      // grant_financing: 0,
      // receivable: 0,
      // owner_equity: 0,
      // in_kind: 0,
      // cash: 0,
      // mfs: 0,
      // bank: 0,
      // other: 0,
    })
  }, [])
  const handleFileUplaod = async (e, filedName) => {
    let img = null;
    let nam = e.target.name;
    let val = e.target.value;
      var type= e.target.files[0].type;
      if (e.target.files && e.target.files[0]) {
        var fsize = e.target.files[0].size/1024;
          if( type ){
          
              try {
                  Resizer.imageFileResizer  (
                  e.target.files[0],
                  600,
                  400,
                  'PNG',
                  100,
                  0,
                  uri =>  {
                    const a = async () => {
                      
                      const res = await uploadFile(uri)
                      if (filedName === 'purchase_material_fl') setPurchaseMaterialFl(res?.data?.name)
                      if (filedName === 'other_material_fl') setOtherMaterialFl(res?.data?.name)
                      if (filedName === 'payable_fl') setPayableFl(res?.data?.name)
                      if (filedName === 'mfs_fl') setMfsFl(res?.data?.name)
                      if (filedName === 'cash_fl') setCashFl(res?.data?.name)
                      if (filedName === 'asset_fl') setAssetFl(res?.data?.name)
                      if (filedName === 'bank_fl') setBankFl(res?.data?.name)
                      if (filedName === 'salary_fl') setSalaryFl(res?.data?.name)
                      if (filedName === 'bonus_fl') setBonusFl(res?.data?.name)
                      if (filedName === 'wages_fl') setWagesFl(res?.data?.name)
                      if (filedName === 'transport_fl') setTransportFl(res?.data?.name)
                      if (filedName === 'utility_fl') setUtilityFl(res?.data?.name)
                      if (filedName === 'entertainment_fl') setEntertainmentFl(res?.data?.name)
                      if (filedName === 'communication_fl') setCommunicationFl(res?.data?.name)
                      if (filedName === 'advertisement_fl') setAdvertisementFl(res?.data?.name)
                      if (filedName === 'marketing_fl') setMarketingFl(res?.data?.name)
                      if (filedName === 'sample_fl') setSampleFl(res?.data?.name)
                      if (filedName === 'intra_transfer_fl') setIntraTransferFl(res?.data?.name)
                      if (filedName === 'credit_amount_fl') setCreditAmountFl(res?.data?.name)
                    }
                    a();
                  },
                  'blob',
                  600,
                  400,
                  );
                  }   
                  catch(err) {
                  }
          }
          else{
  
              alert('Invalid image format');
              return true;
          }
          if(img){
            const res = await uploadFile(img)
          }
      }
  }
  const handleDeleteFile = async (name, fieldName) => {
    const res = await removeFile(name);
    if (res) {

      if (fieldName === 'purchase_material_fl') setPurchaseMaterialFl(res?.data?.name)
      if (fieldName === 'other_material_fl') setOtherMaterialFl(res?.data?.name)
      if (fieldName === 'payable_fl') setPayableFl(res?.data?.name)
      if (fieldName === 'mfs_fl') setMfsFl(res?.data?.name)
      if (fieldName === 'cash_fl') setCashFl(res?.data?.name)
      if (fieldName === 'asset_fl') setAssetFl(res?.data?.name)
      if (fieldName === 'bank_fl') setBankFl(res?.data?.name)
      if (fieldName === 'salary_fl') setSalaryFl(res?.data?.name)
      if (fieldName === 'bonus_fl') setBonusFl(res?.data?.name)
      if (fieldName === 'wages_fl') setWagesFl(res?.data?.name)
      if (fieldName === 'transport_fl') setTransportFl(res?.data?.name)
      if (fieldName === 'utility_fl') setUtilityFl(res?.data?.name)
      if (fieldName === 'entertainment_fl') setEntertainmentFl(res?.data?.name)
      if (fieldName === 'communication_fl') setCommunicationFl(res?.data?.name)
      if (fieldName === 'advertisement_fl') setAdvertisementFl(res?.data?.name)
      if (fieldName === 'marketing_fl') setMarketingFl(res?.data?.name)
      if (fieldName === 'sample_fl') setSampleFl(res?.data?.name)
      if (fieldName === 'intra_transfer_fl') setIntraTransferFl(res?.data?.name)
      if (fieldName === 'credit_amount_fl') setCreditAmountFl(res?.data?.name)
      
    }
  }
 
  const onFinish = async (values) => {
    const res = await withdrawCreate(
      account,
      values, purchaseMaterialFl, otherMaterialFl, payableFl, 
      mfsFl, cashFl, assetFl, bankFl, 
      salaryFl, bonusFl, wagesFl, transportFl,
      utilityFl, entertainmentFl, communicationFl, advertisementFl,
      marketingFl, sampleFl, intraTransferFl, creditAmountFl,
      )
  
    if (res === 201) {
      history.goBack();
    }
  };


  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const calc_total = (newValues) => {
    const { 
      purchase_material, received_from_sale, other_material, credit_amount, payable, asset, salary,
      bonus, wages, transport, utility, entertainment, communication, advertisement, marketing,
      } = newValues;
    const newTotal = parseInt(purchase_material) + parseInt(received_from_sale) + parseInt(other_material)
    - parseInt(credit_amount) + parseInt(payable)
    + parseInt(asset) + parseInt(salary) 
    + parseInt(bonus) + parseInt(wages) 
    + parseInt(transport) + parseInt(utility) 
    + parseInt(entertainment) + parseInt(communication) 
    + parseInt(advertisement) + parseInt(marketing)
    setTotal(newTotal)
  } 
  
  const calc_remove_total = (newValues) => {
    const { cash, mfs, bank
      } = newValues;
    const newTotal =  parseInt(cash) + parseInt(mfs) + parseInt(bank)
    setRest(newTotal)
  }

  const handleTotalDeposite = (e, name) => {
    let value= e;
    const newValues = {
      ...totalDeposte,
      [name]: value ? value : 0 
    } 
    setTotalDeposte(newValues)
    calc_total(newValues) 
  }
  
  const handleRemoveDeposite = (e, name) => {
    let value= e;
    const newValues = {
      ...totalEntry,
      [name]: value ? value : 0 
    } 
    setTotalEntry(newValues)
    calc_remove_total(newValues) 
  }
  const options = [
    {value: 'cash', label: 'নগত থেকে'},
    {value: 'mfs', label: 'মোবাইল ব্যাংকিং থেকে'},
    {value: 'bank', label: 'ব্যাংক থেকে'},
  ]
  const options1 = [
    {value: 'cash', label: 'নগতে'},
    {value: 'mfs', label: 'মোবাইল ব্যাংকিং এ'},
    {value: 'bank', label: 'ব্যাংকে'},
  ]
  const fileds = [
    {
      type: 'input',
      name: 'purchase_material',
      placeholder: 'কাঁচামাল ক্রয়',
      valfnc: (e) => handleTotalDeposite(e, 'purchase_material'),
    },
    {
      type: 'file',
      name: 'purchase_material_fl',
      placeholder: 'ফাইল',
      imageName: purchaseMaterialFl,
      fnc: (e) => handleFileUplaod(e, 'purchase_material_fl'),
    },
    {
      type: 'input',
      name: 'other_material',
      placeholder: 'অন্যান্য মালামাল ক্রয়',
      valfnc: (e) => handleTotalDeposite(e, 'other_material'),
    },
    {
      type: 'file',
      name: 'other_material_fl',
      placeholder: 'ফাইল',
      imageName: otherMaterialFl,
      fnc: (e) => handleFileUplaod(e, 'other_material_fl'),
    },
    {
      type: 'input',
      name: 'credit_amount',
      placeholder: 'বাকি করেছি/ নিয়েছি',
      valfnc: (e) => handleTotalDeposite(e, 'credit_amount'),
    },
    {
      type: 'file',
      name: 'credit_amount_fl',
      placeholder: 'ফাইল',
      imageName: creditAmountFl,
      fnc: (e) => handleFileUplaod(e, 'credit_amount_fl'),
    },
    {
      type: 'input',
      name: 'payable',
      placeholder: 'বাকি পরিশোধ করেছি',
      valfnc: (e) => handleTotalDeposite(e, 'payable'),
    },
    {
      type: 'file',
      name: 'payable_fl',
      placeholder: 'ফাইল',
      imageName: payableFl,
      fnc: (e) => handleFileUplaod(e, 'payable_fl'),
    },
    {
      type: 'input',
      name: 'asset',
      placeholder: 'সম্পদ ক্রয়',
      valfnc: (e) => handleTotalDeposite(e, 'asset'),
    },
    {
      type: 'file',
      name: 'asset_fl',
      placeholder: 'ফাইল',
      imageName: assetFl,
      fnc: (e) => handleFileUplaod(e, 'asset_fl'),
    },
    {
      type: 'input',
      name: 'salary',
      placeholder: 'বেতন',
      valfnc: (e) => handleTotalDeposite(e, 'salary'),
    },
    {
      type: 'file',
      name: 'salary_fl',
      placeholder: 'ফাইল',
      imageName: salaryFl,
      fnc: (e) => handleFileUplaod(e, 'salary_fl'),
    },
    {
      type: 'input',
      name: 'bonus',
      placeholder: 'বোনাস',
      valfnc: (e) => handleTotalDeposite(e, 'bonus'),
    },
    {
      type: 'file',
      name: 'bonus_fl',
      placeholder: 'ফাইল',
      imageName: bonusFl,
      fnc: (e) => handleFileUplaod(e, 'bonus_fl'),
    },
    {
      type: 'input',
      name: 'wages',
      placeholder: 'মজুরি',
      valfnc: (e) => handleTotalDeposite(e, 'wages'),
    },
    {
      type: 'file',
      name: 'wages_fl',
      placeholder: 'ফাইল',
      imageName: wagesFl,
      fnc: (e) => handleFileUplaod(e, 'wages_fl'),
    },
    {
      type: 'input',
      name: 'transport',
      placeholder: 'যাতায়াত খরচ',
      valfnc: (e) => handleTotalDeposite(e, 'transport'),
    },
    {
      type: 'file',
      name: 'transport_fl',
      placeholder: 'ফাইল',
      imageName: transportFl,
      fnc: (e) => handleFileUplaod(e, 'transport_fl'),
    },
    {
      type: 'input',
      name: 'utility',
      placeholder: 'বাড়ি ভাড়া/ বিল',
      valfnc: (e) => handleTotalDeposite(e, 'utility'),
    },
    {
      type: 'file',
      name: 'utility_fl',
      placeholder: 'ফাইল',
      imageName: utilityFl,
      fnc: (e) => handleFileUplaod(e, 'utility_fl'),
    },
    {
      type: 'input',
      name: 'entertainment',
      placeholder: 'আপ্যায়ন খরচ',
      valfnc: (e) => handleTotalDeposite(e, 'entertainment'),
    },
    {
      type: 'file',
      name: 'entertainment_fl',
      placeholder: 'ফাইল',
      imageName: entertainmentFl,
      fnc: (e) => handleFileUplaod(e, 'entertainment_fl'),
    },
    {
      type: 'input',
      name: 'communication',
      placeholder: 'মোবাইল/কুরিয়ার/ইন্টারনেট খরচ',
      valfnc: (e) => handleTotalDeposite(e, 'communication'),
    },
    {
      type: 'file',
      name: 'communication_fl',
      placeholder: 'ফাইল',
      imageName: communicationFl,
      fnc: (e) => handleFileUplaod(e, 'communication_fl'),
    },
    {
      type: 'input',
      name: 'advertisement',
      placeholder: 'বিজ্ঞাপন খরচ',
      valfnc: (e) => handleTotalDeposite(e, 'advertisement'),
    },
    {
      type: 'file',
      name: 'advertisement_fl',
      placeholder: 'ফাইল',
      imageName: advertisementFl,
      fnc: (e) => handleFileUplaod(e, 'advertisement_fl'),
    },
    {
      type: 'input',
      name: 'marketing',
      placeholder: 'মার্কেটিং খরচ',
      valfnc: (e) => handleTotalDeposite(e, 'marketing'),
    },
    {
      type: 'file',
      name: 'marketing_fl',
      placeholder: 'ফাইল',
      imageName: marketingFl,
      fnc: (e) => handleFileUplaod(e, 'marketing_fl'),
    }, 
    {
      type: 'input',
      name: 'sample',
      placeholder: 'নমুনা পন্য বাবদ খরচ',
      valfnc: (e) => console.log(e),
    },
    {
      type: 'file',
      name: 'sample_fl',
      placeholder: 'ফাইল',
      imageName: sampleFl,
      fnc: (e) => handleFileUplaod(e, 'sample_fl'),
    },
    {
      type: 'input',
      name: 'intra_transfer',
      placeholder: 'পরিমাণ',
      valfnc: (e) => setIntraTransfer(e),
    },
    {
      type: 'file',
      name: 'intra_transfer_fl',
      placeholder: 'ফাইল',
      imageName: intraTransferFl,
      fnc: (e) => handleFileUplaod(e, 'intra_transfer_fl'),
    },
    {
      type: 'select',
      name: 'intra_transfer_from',
      placeholder: 'উত্তোলন হয়েছে',
    },
    {
      type: 'select',
      name: 'intra_transfer_to',
      placeholder: 'জমা হয়েছে',
    },
    {
      type: 'input',
      name: 'cash',
      placeholder: 'নগত খরচ',
      valRfnc: (e) => handleRemoveDeposite(e, 'cash'),
    },
    {
      type: 'file',
      name: 'cash_fl',
      placeholder: 'ফাইল',
      imageName: cashFl,
      fnc: (e) => handleFileUplaod(e, 'cash_fl'),
    },
    {
      type: 'input',
      name: 'mfs',
      placeholder: 'মোবাইলের মাধ্যমে খরচ',
      valRfnc: (e) => handleRemoveDeposite(e, 'mfs'),
    },
    {
      type: 'file',
      name: 'mfs_fl',
      placeholder: 'ফাইল',
      imageName: mfsFl,
      fnc: (e) => handleFileUplaod(e, 'mfs_fl'),
    },
    {
      type: 'input',
      name: 'bank',
      placeholder: 'ব্যাংকের মাধ্যমে খরচ',
      valRfnc: (e) => handleRemoveDeposite(e, 'bank'),
    },
    {
      type: 'file',
      name: 'bank_fl',
      placeholder: 'ফাইল',
      imageName: bankFl,
      fnc: (e) => handleFileUplaod(e, 'bank_fl'),
    },
    
  ];
  console.log(intraTransfer);
  return(
    <Content>
        
        <Breadcrumb
          items={[
            {
              title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
            },
            {
              title: "টাকা খরচ",
            },
            {
              title: (<RollbackOutlined onClick={() => history.goBack()} />),
            },
          ]}
        />
      <br />
      <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          form={form}
          autoComplete={false}
          requiredMark={false}
          colon={false}
          layout="vertical"
        >
        <Row gutter={0}>
          <Row gutter={20}>
            {fileds?.map(item => (
              item.type === 'file'
              ? 
                <>
                  <Col xs={8} md={4}>
                    <Form.Item
                        name={item?.name}
                        style={{ width: '100%' }}
                        label="&nbsp;"
                    >
                        <div class="upload-btn-wrapper">
                          <button class="btn" ><UploadOutlined /> &nbsp;আপ্লোড</button>
                          <Input id="actual-btn" type="file" name={item?.name} onChange={(e)=>item?.fnc(e)}/>
                        
                        </div>
                        {item?.imageName ? 
                        <p style={{ fontSize: "10px" }} onClick={() => handleDeleteFile(item?.imageName, item?.name)}><FcEmptyTrash /> ফাইলটি রিমুভ করুন</p>
                        :
                        null
                      }
                    </Form.Item>
                  </Col>
                </>
              :
              item?.valfnc ?
                item?.name === 'intra_transfer' ?
                  <>
                  <Col xs={24}>
                  <p style={{ textAlign: 'center', borderBottom: '1px solid lightgray', fontSize: '16px', fontWeight: 'bold'}}>
                    টাকা স্থানাস্তর
                    </p>
                    <br />
                  </Col>
                    <Col xs={16} md={10}>
                      <Form.Item
                          label={item?.placeholder}
                          name={item?.name}
                          rules={[
                            { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                          ]}
                          >
                          <InputNumber onChange={(e) => item?.valfnc(e)} name={item?.name} min={0} placeholder={item?.placeholder} type='number' addonAfter={<TbCurrencyTaka />} />
                      </Form.Item>
                    </Col>
                </>
                :
                  <Col xs={16} md={8}>
                    <Form.Item
                        label={item?.placeholder}
                        name={item?.name}
                        rules={[
                          { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                        ]}
                        >
                        <InputNumber onChange={(e) => item?.valfnc(e)} name={item?.name} min={0} placeholder={item?.placeholder} type='number' addonAfter={<TbCurrencyTaka />} />
                    </Form.Item>
                  </Col>
              :
              item?.name === 'cash' ?
                <>
                  <Col xs={24}>
                    {(total !== 0 && rest === total) ? 
                    <p style={{ textAlign: 'center', borderBottom: '1px solid lightgray', fontSize: '16px', fontWeight: 'bold'}}>
                      <strong style={{color: 'green', fontSize: '18px',}}>{total - rest}({total})</strong> টাকা মিলাতে হবে
                    </p>
                    :
                    <p style={{ textAlign: 'center', borderBottom: '1px solid lightgray', fontSize: '16px', fontWeight: 'bold'}}>
                      <strong style={{color: 'red', fontSize: '18px',}}>{total - rest}({total})</strong> টাকা মিলাতে হবে
                    </p>
                    }<br />
                  </Col>
                  <Col xs={16} md={8}>
                    
                    <Form.Item
                        label={item?.placeholder}
                        name={item?.name}
                        rules={[
                          { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                        ]}
                        >
                        <InputNumber onChange={(e) => item?.valRfnc(e)} name={item?.name} min={0} placeholder={item?.placeholder} type='number' addonAfter={<TbCurrencyTaka />} />
                    </Form.Item>
                  </Col> 
                </>
                :
                
                item.type === 'select' ?
                  intraTransfer > 0 ?
                    <Col xs={12}>
                      <Form.Item
                          label={item?.placeholder}
                          name={item?.name}
                          rules={[
                            { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                          ]}
                          >
                            <Select
                                showSearch
                                placeholder="মাধ্যম বাছাই করুন"
                                size="middle"
                                allowClear
                            >
                                {item?.name === 'intra_transfer_from' ? 
                                  options?.map(item => (
                                    <Option value={item.value}>{item.label}</Option>
                                    ))
                                : 
                                  options1?.map(item => (
                                    <Option value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                      </Form.Item>
                    </Col>
                  : null 
                : 
                <Col xs={16} md={8}>
                  <Form.Item
                      label={item?.placeholder}
                      name={item?.name}
                      rules={[
                        { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                      ]}
                      >
                      <InputNumber onChange={(e) => item?.valRfnc(e)} name={item?.name} min={0} placeholder={item?.placeholder} type='number' addonAfter={<TbCurrencyTaka />} />
                  </Form.Item>
                </Col> 
                

            ))} 
          </Row>
          <Col xs={24} md={12}>
            {renderTextField('note', 'Note', [
              { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
            ], '', 'middle')} 
          </Col> 
          <Col xs={24}><br /></Col>
          <Col xs={24}>
              <Form.Item>
              <Button type="primary" htmlType="submit" disabled={( rest !== total) ? true : false}>
              দাখিল করুন
              </Button>
              </Form.Item>
          </Col>
        </Row>
      </Form>

    </Content>
  );
};

export default connect(null,{ withdrawCreate, uploadFile, removeFile })(WithdrawMoney);
