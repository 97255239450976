import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col,Card,Button, Breadcrumb, Table, Tag, Space, Input  } from "antd";
import {
  LikeOutlined,InfoCircleOutlined, CustomerServiceOutlined, HomeOutlined, SearchOutlined, RollbackOutlined
 
} from "@ant-design/icons";
import history from "../../history";

import { CardTitle, SubTitle, TitleH1 } from "./Style";
import CreateSubOrganization from "../enterprice/CreateSubOrganization";
import './style.css';
import { operationDetail, UserDetails } from "../../actions/operation";
import { connect } from "react-redux";
import ForwardFloatButton from "antd/es/float-button/FloatButton";
import Highlighter from "react-highlight-words";
const Search = Input.Search;
const UserDetail = ({ operationDetail, UserDetails }) => {
  const [data, setData] = useState([]);
  const [access, setAccess] = useState('');
  const [createSubOrg, setCreateSubOrg] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  
  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  useEffect(async () =>  {
    window.scrollTo(0, 0)
    
    const res =  await UserDetails();
    setData(res)
    setAccess(JSON.parse(localStorage.getItem('access')))

  }, []);

  
  const createData = (id, name, email, account, status) => {
    return {id, name, email, account, status};
  };

  const columns = [
    {
      title: 'আইডি',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
      align: 'center',
    },
    {
      title: 'নাম',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      align: 'center',
      ...getColumnSearchProps('subject'),
    },
    {
      title: 'একাউন্ট',
      dataIndex: 'account',
      key: 'account',
      width: '30%',
      align: 'center',
      ...getColumnSearchProps('subject'),
    },
    {
      title: 'স্টেটাস',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      align: 'center',
    },
  ];

  const rows = [
    data
      ? data?.map((item, key) => createData(
        key + 1, 
        item?.name, 
        item?.email, 
        !item?.enterprise_admin ? item?.account?.map(item => (<p style={{ width: 'auto', padding: '0px', margin: '2px 0px', backgroundColor: 'rgba(236, 240, 241,0.8)'}}>{item?.name}, </p>)) : 'সকল একাউন্ট দেখতে পারেন', 
        item?.authenticated ? <Tag color="#27ae60">সচল</Tag> : <Tag color="#f50">সচল নয়</Tag>, 
        item?.created_at ? makeLocalTime(item?.created_at) : null, 
        
        ))
      : "",
  ];

  const createorg = () => {
    setCreateSubOrg(true);
  };
  return (
    <Content>  
      
        <Breadcrumb
            items={[
            {
                title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
            },
            {
                title: (
                <>
                    <span>ইউজার সমুহ</span>
                </>
                ),
            },
            {
              title: (<RollbackOutlined onClick={() => history.goBack()} />),
            },
            ]}
        />
        {data ? 
        <Table size="small" scroll={{ x: false }} columns={columns} tableLayout='auto' pagination={false} dataSource={rows[0]} sticky/>
        :null}
    </Content>
   
  );
};

export default connect(null, {operationDetail, UserDetails})(UserDetail);