import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col,Card,Button, Tag, Descriptions, Modal, Table, Space, Input, Breadcrumb } from "antd";
import {
  LikeOutlined,InfoCircleOutlined, SearchOutlined, CustomerServiceOutlined, ShopOutlined, HomeOutlined, RollbackOutlined
 
} from "@ant-design/icons";
import history from "../../history";

import { CardTitle, SubTitle, TitleH1 } from "../../Style";
import CreateSubOrganization from "./CreateSubOrganization";
import { operationDetailById } from "../../actions/operation";
import { connect } from "react-redux";
import { Container, Title, Wrapper } from "../../GlobalStyle";
import Highlighter from "react-highlight-words";
import ForwardFloatButton from "antd/es/float-button/FloatButton";
import { 
  FcManager, FcBriefcase, FcUpLeft, FcShop, FcMoneyTransfer , FcLibrary, FcPhoneAndroid, 
  FcUpRight, 
 } from "react-icons/fc";
import './style.css';
import { getLoanStat } from "../../actions/loan";
const Search = Input.Search;

const Enterprise = ({ location, operationDetailById, getLoanStat }) => {
  const [loanData, setLoanData] = useState('');
  const [data, setData] = useState(null);
  const [products, setProducts] = useState(null);
  const [modal, setModal] = useState(false);
  const [createSubOrg, setCreateSubOrg] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);

  useEffect( async () => {
    window.scrollTo(0, 0)
    const id = new URLSearchParams(location.search).get("id");
    const res =  await operationDetailById(id);
    setData(res);

    const loan = await getLoanStat(id);
    setLoanData(loan)
  }, []);

  const handleProductView = async (id) => {
    history.push(`/product-list?id=${id}`)
  };
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (name, production_cost, sale_price) => {
    return {name, production_cost, sale_price};
  };

  const columns = [
    {
      title: 'নাম',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      align: 'center',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'উৎপাদন খরচ',
      dataIndex: 'production_cost',
      key: 'production_cost',
      width: '20%',
      align: 'center',
    },
    {
      title: 'বিক্রয় মূল্য',
      dataIndex: 'sale_price',
      key: 'sale_price',
      width: '15%',
      align: 'center',
    },
    // {
    //   title: 'স্টেটাস',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: '20%',
    //   align: 'center',
    // },
    // {
    //   title: 'একশন',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: '20%',
    //   align: 'center',
    // },
  ];

  const rows = [
    products
      ? products?.map((item, key) => createData(
        item?.name, 
        item?.production_cost, 
        item?.sale_price, 
        // item?.active ? <Tag color="#f50">চলমান</Tag> : <Tag color="#27ae60">সমাধান হয়েছে</Tag>, 
        <>
          {/* <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleView(item?.id)}>বিস্তারিত দেখুন</Button> &nbsp; */}
         
        </>
        ))
      : "",
  ];
  const createorg = () => {
    setCreateSubOrg(true);
  };
  // console.log(access)
  return (
  <Content>  
    <Breadcrumb
        items={[
          {
            title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
          },
          {
            title: (<RollbackOutlined onClick={() => history.goBack()} />),
          },
        ]}
      />
    <Row gutter={20}>
      <Col xs={16} lg={20} md={20} sm={20}>
        <TitleH1>স্বাগতম</TitleH1>
      </Col>
      {data?.type === 'production' ? 
        <Col xs={8} lg={4} md={4} sm={4}>
          <Button size="middle" type="primary" onClick={() => handleProductView(data?.id)}>প্রোডাক্ট সমূহ</Button>
        </Col>
      : null}
    </Row>
    {data ? 
      <>
        <SubTitle style={{ margin: '2px 0px', fontSize: '22px'}}> {data?.name} </SubTitle>
        <Tag color="#2db7f5" style={{ margin: '5px 5px'}}>মোবাইলঃ {data?.mobile_number}</Tag>
        <Tag color="#2db7f5" style={{ margin: '5px 5px'}}>ইমেইলঃ {data?.email}</Tag>
        <Tag color="#2db7f5" style={{ margin: '5px 5px'}}>ধরনঃ {data?.type === 'production' ? 'প্রোডাকশন' : 'ট্রেডিং'}</Tag>
        
        <Row gutter={10}>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px"}}>
            <Card className="cardColor" style={{ minHeight: '135px', maxHeight: '110px'}} >
              <Row gutter={40}>
                <Col xs={8}>
                  <FcManager className="cardLogo" />
                </Col>
                <Col xs={16}>
                  <SubTitle className="subtitle">মালিকের বিনিয়োগ </SubTitle>
                  <h1 className="subTitleH1">{data?.owners_equity}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}}>
              <Row gutter={40}>
                <Col xs={8}>
                  <FcBriefcase className="cardLogo" />
                </Col>
                <Col xs={16}>
                  <SubTitle className="subtitle">স্থায়ী সম্পদের মূল্য </SubTitle>
                  <h1 className="subTitleH1">{data?.asset}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}} >
              <Row gutter={40}>
                <Col xs={8}>
                  <FcUpLeft className="cardLogo" />
                </Col>
                <Col xs={16}>
                  <SubTitle className="subtitle"> দায়-দেনা </SubTitle>
                  <h1 className="subTitleH1">{data?.payable}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}} >
              <Row gutter={40}>
                <Col xs={8}>
                  <FcUpRight className="cardLogo" />
                </Col>
                <Col xs={16}>
                  <SubTitle className="subtitle">পাওনা</SubTitle>
                  <h1 className="subTitleH1">{data?.receivable}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
          <Card  className="cardColor" style={{ minHeight: '135px'}} >
            <Row gutter={40}>
              <Col xs={8}>
                <FcShop className="cardLogo" />
              </Col>
              <Col xs={16}>
            <SubTitle className="subtitle">মালপত্রের হিসাব</SubTitle>
            <h1 className="subTitleH1">{data?.inventory}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}}>
              <Row gutter={40}>
                <Col xs={8}>
                  <FcMoneyTransfer className="cardLogo" />
                </Col>
                <Col xs={16}>
                  <SubTitle className="subtitle">হাতে নগদ টাকা</SubTitle>
                  <h1 className="subTitleH1">{data?.cash}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}}>
              <Row gutter={40}>
                <Col xs={8}>
                  <FcLibrary className="cardLogo" />
                </Col>
                <Col xs={16}>
                  <SubTitle className="subtitle">ব্যাংকে জমা টাকা</SubTitle>
                  <h1 className="subTitleH1">{data?.bank}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}} >
            <Row gutter={40}>
              <Col xs={8}>
                <FcPhoneAndroid className="cardLogo" />
              </Col>
              <Col xs={16}>
                <SubTitle  className="subtitle" title="Mobile Banking">মোবাইলে জমা টাকা</SubTitle>
              <h1 className="subTitleH1">{data?.mfs}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}} >
            <Row gutter={40}>
              <Col xs={8}>
                <FcUpRight className="cardLogo" />
              </Col>
              <Col xs={16}>
                <SubTitle  className="subtitle" title="Mobile Banking">ঋণ পাওনা</SubTitle>
                <h1 className="subTitleH1">{loanData?.taken}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          
          <Col xs={12} lg={8} md={8} sm={12} style={{ marginTop: "10px" }}>
            <Card  className="cardColor" style={{ minHeight: '135px'}} >
            <Row gutter={40}>
              <Col xs={8}>
                <FcUpLeft className="cardLogo" />
              </Col>
              <Col xs={16}>
                <SubTitle  className="subtitle" title="Mobile Banking">ঋণ দেনা</SubTitle>
                <h1 className="subTitleH1">{loanData?.given}৳ </h1>
                </Col>
              </Row>
            </Card>
          </Col>
          
        </Row>

         <br />
         <hr />
         <hr />
         <br />
          {/* <Row gutter={24}>
            <Col span={24}>
              
              {data?.contact_person ?
              <>
                <Title>পরিচিতির ব্যক্তির বিবিরণ</Title>
                <br />
                {data?.contact_person?.map ((item, key) => (
                  <>
                    <h5>ব্যক্তি নং : {key + 1}</h5>
                    <Descriptions title="" size="large" style={{ margin: '2px 0px'}}>
                      <Descriptions.Item label="নাম"><strong>{item?.name}</strong></Descriptions.Item>
                      <Descriptions.Item label="ইমেইন"><strong>{item?.email}</strong></Descriptions.Item>
                      <Descriptions.Item label="ঠিকানা"><strong>{item?.number}</strong></Descriptions.Item>
                      <Descriptions.Item label="বিস্তারিত"><strong>{item?.description}</strong></Descriptions.Item>
                    </Descriptions>
                  </>
                ))}
              </>
              : null}
            </Col>
          </Row> */}

        <Row gutter={20}>
          <Col xs={24} lg={8} md={8} sm={8} style={{ marginTop: "0px" }}>
            <Card className="menuColor" style={{textAlign:'center',maxHeight:'70px'}}
              onClick={() => { history.push(`/deposite?account=${data?.id}`)}}
            >
              <SubTitle>জমা</SubTitle>
            </Card>
          </Col>
          
          
          <Col xs={24} lg={8} md={8} sm={8} style={{ marginTop: "0px" }}>
            <Card className="menuColor" style={{textAlign:'center',maxHeight:'70px'}} 
              onClick={() => { history.push(`/withdraw?account=${data?.id}`)}}
            >
              <SubTitle>খরচ </SubTitle>
            </Card>
          </Col>
          
          
          <Col xs={24} lg={8} md={8} sm={8} style={{ marginTop: "0px" }}>
            <Card className="menuColor" style={{textAlign:'center',maxHeight:'70px'}} 
              onClick={() => { history.push(`/loan-list?account=${data?.id}`)}}
            >
              <SubTitle>ঋণ </SubTitle>
            </Card>
            {createSubOrg? 
              <CreateSubOrganization
                title="নতুন অ্যাকাউন্ট"
                visible={createSubOrg}
                cancel={() => setCreateSubOrg(false)}
                ok={() => setCreateSubOrg(false)}
                // api={createUsers}
                // refresh={handleRefresh}
              />
            :null}
            
          </Col>
          
          
          <Col xs={24} lg={8} md={8} sm={8} style={{ marginTop: "0px" }}>
            <Card className="menuColor" style={{textAlign:'center',maxHeight:'70px'}} 
              onClick={() => { history.push(`/report?account=${data?.id}`)}}
            >
              <SubTitle > রিপোর্ট </SubTitle>
            </Card>
          </Col>
          
          
          {/* <Col xs={24} lg={8} md={8} sm={8} style={{ marginTop: "10px" }}>
            <Card className="menuColor" style={{textAlign:'center',maxHeight:'70px'}}
              onClick={() => { history.push('/setting')}}
            >
              <SubTitle > সেটিং </SubTitle>
            </Card>
              {createSubOrg? 
                <CreateSubOrganization
                  title="নতুন অ্যাকাউন্ট"
                  visible={createSubOrg}
                  cancel={() => setCreateSubOrg(false)}
                  ok={() => setCreateSubOrg(false)}
                />
              :null}
            
          </Col> */}

          
        </Row>
        </>
      : null}

      
      <Modal
        title="প্রোডাক্ট সমূহ" 
        open={modal} 
        onOk={() => setModal(false)} 
        onCancel={() => setModal(false)} 
        width={1000}
        footer={null}
      >
        {products ? 
          <Table columns={columns} dataSource={rows[0]} sticky pagination={false}/>
        : null}
        
      </Modal>
      
      <ForwardFloatButton
        onClick={() => history.push('/tickets')}
        shape="square"
        danger
        type="primary"
        style={{
          right: 24,
          bottom: 20,
        }}
        icon={<CustomerServiceOutlined />}
      />
    </Content>
   
  );
};

export default connect(null, {operationDetailById, getLoanStat})(Enterprise);
