import React, { useEffect, useState } from 'react';
import { Button, Tag, Card, Form, Row, Col, Tooltip, Breadcrumb, Descriptions, Alert, Empty, Timeline, Modal, Input    } from 'antd';
import { CustomerServiceOutlined, DownloadOutlined, HomeOutlined, RollbackOutlined, SendOutlined, } from '@ant-design/icons';
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import { 
  getLoanDetails, LoanPay, loanFileDownloader,
} from '../../actions/loan';
import history from '../../history';
import { renderDate, renderInput, renderSelect, renderTextField } from '../../components/form';
import { BoxWrapper, Container, MessageBox, Title, Wrapper } from '../../GlobalStyle';
import { Content } from 'antd/lib/layout/layout';
import ForwardFloatButton from 'antd/es/float-button/FloatButton';

const Search = Input.Search;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const LoanDetails = ({ 
  getLoanDetails, LoanPay, loanFileDownloader, location,
 }) => {
  const [loan, setLoan] = useState('');
  const [account, setAccount] = useState('');
  const [details, setDetails] = useState('');
  const [modal, setModal] = useState('');
  const [payForm] = Form.useForm();
  const [image, setImage] = useState('');

  const getData = async () => {
    const id = new URLSearchParams(location.search).get("id");
    const loan = new URLSearchParams(location.search).get("loan");
    setAccount(id);
    setLoan(loan);
    const res = await getLoanDetails(id, loan);
    setDetails(res);
  };
  
  useEffect(() => {
    getData();
  }, []);

  const onFinishPayForm = async (data) => {
    const res = await LoanPay(image, data, account, loan );
    if(res ===  201){
      const res = await getLoanDetails(account, loan)
      setDetails(res);
      payForm.resetFields();
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  
  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  
  const handleImageChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
      var type= e.target.files[0].type;
      if (e.target.files && e.target.files[0]) {
        var fsize = e.target.files[0].size/1024;
          // if( type == 'image/jpg' || type == 'image/jpeg' || type == 'image/JPG' || type == 'image/JPEG' || type == 'image/PNG' || type == 'image/png' ){
          if( type ){
          
              try {
                  Resizer.imageFileResizer(
                  e.target.files[0],
                  600,
                  400,
                  'PNG',
                  100,
                  0,
                  uri => {
                      setImage(uri) 
                  },
                  'blob',
                  600,
                  400,
                  );
                  }   
                  catch(err) {
                  }
          }
          else{
  
              alert('Invalid image format');
              return true;
          }
      }
  }
  
  const status2 = [
    { value: "bank", label: "ব্যাংক" },
    { value: "cash", label: "নগদ" },
    { value: "mfs", label: "মোবাইল ব্যাংকিং" },
  ];

  return (
    <Content>
      <Breadcrumb
        items={[
          {
            title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
          },
          {
            title: (
              <>
                <span>ঋণের বিবিরণ</span>
              </>
            ),
          },
          {
            title: (<RollbackOutlined onClick={() => history.goBack()} />),
          },
        ]}
      />

      <Wrapper>
        <Row gutter={24}>
          <Col xs={24} md={14}>
            <h3 style={{ margin: '3px 0px' }}> ঋণের পরিমাণঃ <strong style={{ fontSize: '24px', color: '#c23616'}}>{details.total_amount}৳</strong></h3>
            <h3 style={{ margin: '3px 0px' }}> পরিশোধ হয়েছেঃ <strong style={{ fontSize: '24px', color: '#4cd137'}}>{details.paid_amount}৳</strong></h3>
          </Col>
          
          <Col xs={24} md={10}>
            {details?.loan_state === 'paid' ? 
              <h3 style={{ margin: '3px 0px' }}><strong style={{ fontSize: '24px', color: details?.loan_state !== 'paid' ? '#c23616' : '#4cd137'}}> বকেয়া নেই</strong></h3>
              :
              <h3 style={{ margin: '3px 0px' }}> {details?.state === 'taken' ? 'বকেয়া আছে' : 'পাওনা আছে'} <strong style={{ fontSize: '24px', color: details?.loan_state !== 'paid' ? '#c23616' : '#4cd137'}}>{details.total_amount - details.paid_amount}৳</strong></h3>
            }
            {details?.loan_state !== 'paid' ? <Button type='primary' size='middle' onClick={() => setModal(true)}>{details?.state === 'taken' ? 'পরিশোধ করুন' : 'গ্রহন করুন'}</Button> : null}
          </Col>
        </Row>
        <br />
        <Tag color="#cd2df5" >আইডিঃ {details.loan_id}</Tag>
        {
          details?.loan_state === 'pending' ? <Tag  style={{ margin:'5px', }} color="#eb4d4b">অপিশোধিত</Tag> :
          details?.loan_state === 'partially paid' ? <Tag  style={{ margin:'5px', }} color="#badc58">আংশিক পরিশোধিত</Tag> :
         <Tag  style={{ margin:'5px', }} color="#6ab04c">পরিশোধিত</Tag>
        }
        
      
        {
          details?.state === 'taken' ? 
          <Tag style={{ margin:'5px',  }} color="#eb4d4b">গ্রহণ</Tag> 
          :
          <Tag style={{ margin:'5px',  }} color="#27ae60">প্রদান</Tag>
        }

        <Tag color="#cd2df5" >{details?.created_at ? makeLocalTime(details?.created_at) : null}</Tag>
        <br />
        <br />
        <Row gutter={24}>
          <Col span={24}>
            
            {details?.account ?
            <>
              <Title>{details?.state === 'given' ? 'দাতার বিবরণ' : 'গ্রহিতার বিবরণ'}</Title>
              <Descriptions title="" size="small">
                
                <Descriptions.Item label="নাম"><strong>{details?.info?.name}</strong></Descriptions.Item>
                <Descriptions.Item label="মোবাইল"><strong>{details?.info?.mobile_number}</strong></Descriptions.Item>
                <Descriptions.Item label="ধরণ"><strong>{
                  details?.info?.type === 'individual' ? 'স্বতন্ত্র' :
                  details?.info?.type === 'company' ? 'প্রতিষ্ঠান' :
                  details?.info?.type === 'bank' ? 'ব্যাংক' :
                  details?.info?.type === 'employee' ? 'কর্মচারী' :
                  'অন্যান্য'
                }</strong></Descriptions.Item>
                <Descriptions.Item label="বিস্তারিত"><strong>{details?.info?.description}</strong></Descriptions.Item>
              </Descriptions>
            </>
            : null}
          </Col>
          {details?.transaction_list?.length > 0 ?
            <Col xs={24} md={24}>
              <Title>লেনদেনের বিবরণ</Title>
              <br />
              <Row gutter={20} style={{ maxHeight: '500px', overflow: 'scroll'}}>
                {details?.transaction_list?.map (item => (
                  <Col xs={24} lg={24} md={24} sm={24} xl={24} style={{ marginTop: "10px"}}>
                    <Card hoverable style={{ 
                      // backgroundColor: (item?.transaction_state !== 'Loan Given') > 0 ? 'rgba(255, 121, 121, 0.3)' : 'rgba(186, 220, 88, 0.3)',
                    }} >
                      <Row gutter={8}>
                        <Col xs={12} lg={8} md={8} sm={12} xl={8} >লেনদেনের আইডিঃ <strong>{item?.transaction_id}</strong> </Col> 
                        <Col xs={12} lg={8} md={8} sm={12} xl={8} >পরিমানঃ <strong>{item?.amount}৳</strong> </Col> 
                        <Col  xs={12} lg={8} md={8} sm={12} xl={8}>ধরণঃ <strong>{
                          item?.transaction_state === 'Loan Given' ? 'ঋণ দেওয়া' :
                          item?.transaction_state === 'Loan Taken' ? 'ঋণ গ্রহণ' :
                          item?.transaction_state === 'Loan Receivable' ? 'ঋণ আদায়' :
                          'ঋণ পরিশোষ'
                        }</strong> </Col>  
                       
                        <Col  xs={12} lg={8} md={8} sm={12} xl={8}>তারিখঃ <strong>{item?.created_at? makeLocalTime(item?.created_at) : null} </strong> </Col>
                        {item?.doc ? <Col  xs={12} lg={8} md={8} sm={12} xl={8}> <Button type='primary' icon={<DownloadOutlined />} onClick={() => loanFileDownloader(item?.doc)} > ডাউনলোড করুন </Button></Col>
                         : null}
                      </Row>
                    </Card>
                  </Col>
                ))}

              </Row>
            </Col>
          : null}
          {/* <Col xs={24} md={0}> <br /> </Col> */}
          {/* <Col xs={24} md={details?.transaction_list?.length > 0 ? 8 : 24}>
            <Title>সকল কার্যাবলি</Title>
            <br />
            <Timeline style={{ padding: '2px 0px', minHeight: '145px', maxHeight: '500px', overflow: 'scroll' }}>
              {details?.logs?.map(lg => (
                <Timeline.Item>
                  <p>{lg?.enterprise_user?.name}</p>
                  <p>{lg?.message}</p>
                  <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{lg?.created_at ? makeLocalTime(lg?.created_at) : null}</p>
                </Timeline.Item>
              ))}
            </Timeline>
          </Col> */}
        </Row>
      </Wrapper>

      
      <Modal 
        title="ঋণ পরিশোষ" 
        open={modal} 
        onOk={()=> setModal(false)} 
        onCancel={()=> setModal(false)} 
        width={1000}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinishPayForm}
          onFinishFailed={onFinishFailed}
          form={payForm}
        >
          {renderDate("date", "তারিখ", [
            { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
          ])} 
          {renderInput("amount", "পরিমাণ", [
            { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
          ])} 
          {renderSelect(
            "payment_method",
            "আদান/প্রদানের মাধ্যম",
              [ { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" }],
            "Select a option",
            status2,
            "none"
            )}
        
            {renderTextField("note", "বর্ণনা", [
              { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
            ])} 
        
            <Form.Item
              label="ডকুমানেত এর ছবি"
              name="loan_doc"
              rules={[
              {
                  required: false,
                  message: "This field is required",
              },
              ]}
            >
              <Input type="file" name="loan_doc" onChange={(e)=>handleImageChange(e)}/>
            </Form.Item>
          
            <Form.Item {...tailLayout}> 
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
                
          </Form>
      </Modal>
      <ForwardFloatButton
        onClick={() => history.push('/tickets')}
        shape="square"
        danger
        type="primary"
        style={{
          right: 24,
          bottom: 20,
        }}
        icon={<CustomerServiceOutlined />}
      />
    </Content>
  );
};

export default connect(null, { 
  getLoanDetails, LoanPay, loanFileDownloader,
 })(LoanDetails);
