import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Space, DatePicker,Tag, Modal, Form, Pagination, Row, Col, Select, Breadcrumb, Card, } from 'antd';
import Highlighter from 'react-highlight-words';
import { CustomerServiceOutlined, DeleteOutlined, EditOutlined, HomeOutlined, PlusCircleFilled, RollbackOutlined, SearchOutlined, } from '@ant-design/icons';
import { connect } from 'react-redux';
import { 
  getLoan, LoanCreate, getLoanDetails,
} from '../../actions/loan';
import Resizer from 'react-image-file-resizer';
import history from '../../history';
import { renderDate, renderInput, renderSelect, renderTextField } from '../../components/form';
import { Content } from 'antd/lib/layout/layout';
import ForwardFloatButton from 'antd/es/float-button/FloatButton';
// import ViewAgent from './view';
const Search = Input.Search;
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const LoanList = ({ 
  getLoan, LoanCreate, location,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [lid, setLId] = useState(''); 
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [account, setAccount] = useState(null); 
  const [ls, setLs] = useState(null); 
  const [page, setPage] = useState(1); 
  const [subject, setSubject] = useState(null);
  const [state, setState] = useState(null);
  const [ds, setDs] = useState('');
  const [de, setDe] = useState('');
  const [allAcount, setAllAcount] = useState(null);
  const [allSubject, setAllSubject] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);
  const [image, setImage] = useState('');

  const [form] = Form.useForm();


  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const createOption = (value, label) => {
    const a = '';
    return { value, label };
  };
  const tstatus = [
    {value: 'active', label: 'চলমান টিকেট সমূহ'},
    {value: 'solved', label: 'মীমাংসিত টিকেট সমূহ'},
    {value: '', label: 'সবগুলো টিকেট'},
  ]
  const getData = async () => {
    
    const id = new URLSearchParams(location.search).get("account");
    setAccount(id);
    const res = await getLoan(id, lid, ls, state, ds, de);
    setData(res?.list);
  };
  

  useEffect(() => {
    getData();
  }, []);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const handleApiSearch = async () => {
    const res = await getLoan(account, lid, ls, state, ds, de);
    setData(res?.list);
  };

  const handleApiResetSearch = async () => {
    setLId('');
    setLs(null);
    setState(null);
    setDs('');
    setDe('');
    const res = await getLoan(account, '', '', '', '', '');
    setData(res?.list);
  };
  
  const handleDate = (date) => {
    if (date) {
      console.log(date[0]?.$d)
      const ds = new Date(date[0]?.$d);
      const de = new Date(date[1].$d);
      setDs((ds.toISOString()).split('T')[0]);
      setDe(de.toISOString().split('T')[0])
    } else {
      setDs(null);
      setDe(null)
      return;
    }
  };
  
  const handleView = async (id) => {
    history.push(`/loan-details?id=${account}&loan=${id}`)
  };

  const createData = (id, issue_name, taka, status, created_at, action) => {
    return {id, issue_name, taka, status, created_at, action};
  };

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      align: 'center',
      
    },
    {
      title: 'নাম',
      dataIndex: 'issue_name',
      key: 'issue_name',
      width: '20%',
      align: 'center',
      ...getColumnSearchProps('issue_name'),
    },
    {
      title: 'টাকা',
      dataIndex: 'taka',
      key: 'taka',
      width: '20%',
      align: 'center',
      ...getColumnSearchProps('taka'),
    },
    {
      title: 'স্টেটাস',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      align: 'center',
    },
    {
      title: 'সময়',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
      align: 'center',
    },
    {
      title: 'একশন',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'center',
    },
  ];

  const capitalizer = s => 
    s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
  
  const rows = [
    data
      ? data?.map((item, key) => createData(
        item?.loan_id, 
        item?.issue_name, 
        <>
          <p>পরিশোধিতঃ {item?.paid_amount}</p>
          <p>মোটঃ {item?.total_amount}</p>
        </>, 
        <>
          <p>ঋণের স্টেটাস {item?.loan_state}</p>
          <p>ঋণ এর ধরন {item?.state}</p>
        </>, 
        item?.created_at ? makeLocalTime(item?.created_at) : null, 
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleView(item?.loan_id)}>বিস্তারিত</Button> &nbsp;
        </>
        ))
      : "",
  ];
  
  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const onFinish = async (values) => {
    const res = await LoanCreate(values, image, account);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      form.resetFields();
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  
  const status = [
    { value: "taken", label: "গ্রহণ" },
    { value: "given", label: "প্রদান" },
  ];
  const status1 = [
    { value: "individual", label: "স্বতন্ত্র" },
    { value: "company", label: "প্রতিষ্ঠান" },
    { value: "bank", label: "ব্যাংক" },
    { value: "employee", label: "কর্মচারী" },
    { value: "other", label: "অন্যান্য" },
  ];
  const status2 = [
    { value: "bank", label: "ব্যাংক" },
    { value: "cash", label: "নগদ" },
    { value: "mfs", label: "মোবাইল ব্যাংকিং" },
  ];
  const loanStatus = [
    { value: "pending", label: "অপিশোধিত" },
    { value: "partially paid", label: "আংশিক পরিশোধিত" },
    { value: "paid", label: "পরিশোধিত" },
  ];

  const handleImageChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
      var type= e.target.files[0].type;
      if (e.target.files && e.target.files[0]) {
        var fsize = e.target.files[0].size/1024;
          // if( type == 'image/jpg' || type == 'image/jpeg' || type == 'image/JPG' || type == 'image/JPEG' || type == 'image/PNG' || type == 'image/png' ){
          if( type ){
          
              try {
                  Resizer.imageFileResizer(
                  e.target.files[0],
                  600,
                  400,
                  'PNG',
                  100,
                  0,
                  uri => {
                      setImage(uri) 
                  },
                  'blob',
                  600,
                  400,
                  );
                  }   
                  catch(err) {
                  }
          }
          else{
  
              alert('Invalid image format');
              return true;
          }
      }
  }
  return (
    <Content>
      <Breadcrumb
        items={[
          {
            title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
          },
          {
            title: (
              <>
                <span>ঋণ সমুহ</span>
              </>
            ),
          },
          {
            title: (<RollbackOutlined onClick={() => history.goBack()} />),
          },
        ]}
      />
      
      <Row gutter={10}>
        <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
            <Input value={lid} size="middle" placeholder="ঋণ আইডি" allowClear style={{width:'99%'}} onChange={(e)=>setLId(e.target.value)} />
        </Col>
        
         <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
          <Select
            placeholder="ঋণ স্টেটাস সিলেক্ট করুণ"
            size="middle"
            allowClear
            style={{width:'99%'}}
            onChange={(e)=> setLs(e)}
            value={ls}
          >
            {loanStatus?.map(item => (
                <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Col>
        
         <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
          <Select
            placeholder="ঋণের ধরণ"
            size="middle"
            allowClear
            style={{width:'99%'}}
            onChange={(e)=> setState(e)}
            value={state}
          >
            {status?.map(item => (
                <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Col>

        <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
              <RangePicker
                onChange={handleDate}
                format="YYYY/MM/DD"
                style={{ width: "99%" }}
              />
        </Col>
       
        <Button  style={{margin: '5px 5px'}} size="middle" type="primary" onClick={handleApiSearch} icon={<SearchOutlined />}>খুজুন </Button>
        <Button  style={{margin: '5px 5px'}} size="middle" type="primary" danger onClick={handleApiResetSearch} icon={<DeleteOutlined />}>ফিল্টার মুছুন</Button>
        <Button style={{margin: '5px 5px'}} size="middle" type="primary" icon={<PlusCircleFilled />} onClick = { showModal }>নতুন তৈরি</Button>
      </Row>
      <br />
      
      <Row gutter={20} style={{ maxHeight: '500px', overflow: 'scroll'}}>
        {data?.map (item => (
          <Col xs={24} lg={24} md={24} sm={24} xl={24} style={{ marginTop: "10px"}}>
            <Card hoverable style={{ 
                  backgroundColor: 
                  item?.loan_state === 'pending' ? 'rgba(255, 121, 121, 0.3)' :
                  item?.loan_state === 'partially paid' ? 'rgba(251, 197, 49, 0.4)' :
                  'rgba(186, 220, 88, 0.3)',
                }} >
              <Row gutter={8}> 
                <Col xs={18}>
                  <Row gutter={8}>
                    <Col xs={12} lg={8} md={8} sm={12} xl={8} >আইডিঃ <strong>{item?.loan_id}</strong> </Col> 
                    <Col xs={12} lg={8} md={8} sm={12} xl={8} >নামঃ <strong>{item?.issue_name}</strong> </Col> 
                    <Col  xs={12} lg={8} md={8} sm={12} xl={8}>স্ট্যাটাসঃ <strong>{
                      item?.loan_state === 'pending' ? 'অপরিশোধিত' :
                      item?.loan_state === 'partially paid' ? 'আংশিক পরিশোধিত' :
                      'পরিশোধিত'
                    }</strong> </Col>  
                    <Col  xs={12} lg={8} md={8} sm={12} xl={8}>মোটঃ <strong>{item?.total_amount}৳</strong> </Col>
                    <Col  xs={12} lg={8} md={8} sm={12} xl={8}>পরিশোধিতঃ <strong>{item?.paid_amount}৳</strong> </Col>
                    <Col  xs={12} lg={8} md={8} sm={12} xl={8}>ঋণ এর ধরনঃ <strong>{
                      item?.state === 'taken' ? <Tag style={{ margin:'5px',  }} color="#eb4d4b">গ্রহণ</Tag> : <Tag style={{ margin:'5px',  }} color="#27ae60">প্রদান</Tag>
                    }</strong> </Col>
                    <Col  xs={12} lg={8} md={8} sm={12} xl={8}>তারিখঃ <strong>{item?.created_at? makeLocalTime(item?.created_at) : null} </strong> </Col>
                  </Row>
                </Col>
                
                <Col xs={6}>
                  <Row gutter={8}  style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Col xs={24} lg={12} md={12} sm={12} xl={12}  style={{ justifyContent: 'center', }}>
                      {(item?.total_amount - item?.paid_amount) > 0 ? 
                        <p style={{color: '#eb4d4b'}}>বকেয়াঃ <strong>{item?.total_amount - item?.paid_amount}৳</strong></p> 
                      :
                        <p style={{color: '#6ab04c'}}><strong>কোনো বকেয়া নেই</strong></p> 
                      }
                    </Col>
                    <Col xs={24} lg={12} md={12} sm={12} xl={12}>
                      <Button
                        type="primary"
                        style={{width:'100%',}}
                        onClick={() => handleView(item?.loan_id)}
                      >
                        দেখুন
                      </Button>
                    </Col>
                  </Row>
                </Col>
            </Row>
            </Card>
          </Col>
        ))}

      </Row>
      {/* <Table size='large' columns={columns} tableLayout='auto' pagination={false} dataSource={rows[0]} sticky/> */}
      
      <Modal 
        title="নতুন ঋণ" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={1000}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
         {renderInput("name", "নাম", [
          { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])} 
         {renderInput("mobile_number", "মোবাইল নাম্বার", [
          { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
          { pattern: /^(01)[0-9][0-9]{8}$/, message: "দয়া করে সঠিক মোবাইল নম্বরটি দেন!" },
        ])}
         {renderSelect(
          "type",
          "ধরন",
          [ { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" }],
          "Select a option",
          status1,
          "none"
        )} 
        {renderSelect(
          "state",
          "ঋণ এর ধরন",
          [ { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" }],
          "Select a option",
          status,
          "none"
        )}
        {renderDate("date", "তারিখ", [
         { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])} 
        {renderInput("amount", "পরিমাণ", [
          { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])} 
         {renderSelect(
          "payment_method",
          "আদান/প্রদানের মাধ্যম",
          [ { required: true, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" }],
          "Select a option",
          status2,
          "none"
        )}
        
        {renderTextField("note", "বর্ণনা", [
          { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
        ])} 
        
        <Form.Item
              label="ডকুমানেত এর ছবি"
              name="loan_doc"
              rules={[
              {
                  required: false,
                  message: "This field is required",
              },
              ]}
          >
            <Input type="file" name="loan_doc" onChange={(e)=>handleImageChange(e)}/>
        </Form.Item>
       
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      <ForwardFloatButton
        onClick={() => history.push('/tickets')}
        shape="square"
        danger
        type="primary"
        style={{
          right: 24,
          bottom: 20,
        }}
        icon={<CustomerServiceOutlined />}
      />
    </Content>
  );
};

export default connect(null, { 
  getLoan, LoanCreate, getLoanDetails,
 })(LoanList);
