import React from 'react'
import { DatePicker, Form } from 'antd';

const DateBox = ({label,name, value, rules, ...rest}) => {
    return ( 
       
        <Form.Item
            label={label}
            name={name}
            rules={rules}
            
            >
            <DatePicker style={{width: '100%'}} size="large" name={name} value={value} {...rest} format="DD/MM/YYYY"/>
        </Form.Item>
     );
}
 
export default DateBox;