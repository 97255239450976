import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col, Card, Button, Input, Table } from "antd";
import {
  LikeOutlined, InfoCircleOutlined, SearchOutlined, DownloadOutlined

} from "@ant-design/icons";
import history from "../../history";
import { connect } from "react-redux";
import { CardTitle, DetailsMianDiv, DetailsSubDiv, DetailsSubDiv1, DetailsSubDiv2, DetailsSubDiv3, SubTitle, TitleH1, TitleN } from "../../Style";

import { getUsers } from "../../actions/user";
import { fileDownloader } from "../../actions/deposite";

const WithdrawDetails = ({ data, fileDownloader }) => {

  const [createSubOrg, setCreateSubOrg] = useState(false);
  // const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    // setData(data);
  }, []);

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
  });

  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };
  
  const dataSet = [
    {
      title: 'কাঁচামাল ক্রয়',
      value: data?.purchase_material,
      file: data?.purchase_material_fl,
    },
    {
      title: 'অন্য কাঁচামাল ক্রয়',
      value: data?.other_material,
      file: data?.other_material_fl,
    },
    {
      title: 'ঋণ প্রদান/অগ্রীম',
      value: data?.credit_amount,
      file: data?.credit_amount_fl,
    },
    {
      title: 'বাকি পরিশোধ',
      value: data?.payable,
      file: data?.payable_fl,
    },
    {
      title: 'সম্পদ ক্রয়',
      value: data?.asset,
      file: data?.asset_fl,
    },
    {
      title: 'বেতন',
      value: data?.salary,
      file: data?.salary_fl,
    },
    {
      title: 'বোনাস',
      value: data?.bonus,
      file: data?.bonus_fl,
    },
    {
      title: 'মজুরি',
      value: data?.wages,
      file: data?.wages_fl,
    },
    {
      title: 'যাতায়াত খরচ',
      value: data?.transport,
      file: data?.transport_fl,
    },
    {
      title: 'ইউটিলিটিস',
      value: data?.utility,
      file: data?.utility_fl,
    },
    {
      title: 'আপ্যায়ন খরচ',
      value: data?.entertainment,
      file: data?.entertainment_fl,
    },
    {
      title: 'মোবাইল/কুরিয়ার/ইন্টারনেট খরচ',
      value: data?.communication,
      file: data?.communication_fl,
    },
    {
      title: 'বিজ্ঞাপন খরচ',
      value: data?.advertisement,
      file: data?.advertisement_fl,
    },
    {
      title: 'মার্কেটিং খরচ',
      value: data?.marketing,
      file: data?.marketing_fl,
    },
    {
      title: 'নমুনা পন্য বাবদ খরচ',
      value: data?.sample,
      file: data?.sample_fl,
    },
    {
      title: `
        ${data?.intra_transfer_from ? 
          data?.intra_transfer_from === 'cash' ?   'ক্যাশ থেকে' : data?.intra_transfer_from === 'mfs' ? 'মোবাইল থেকে' : 'ব্যাংকে থেকে' : ''} 
          ${
          data?.intra_transfer_to === 'mfs' ?  'মোবাইলে ট্রান্সফার' : data?.intra_transfer_to === 'cash' ? 'ক্যাশে ট্রান্সফার' : 'ট্রান্সফার'}
          `,
      value: data?.intra_transfer ? data?.intra_transfer : '',
      file: data?.intra_transfer_fl,
    },
    {
      title: 'নগত',
      value: data?.cash,
      file: data?.cash_fl,
    },
    {
      title: 'মোবাইল ব্যাংকিং',
      value: data?.mfs,
      file: data?.mfs_fl,
    },
    {
      title: 'ব্যাংক',
      value: data?.bank,
      file: data?.bank_fl,
    },
  ]
  return (
    <Content
    >

      <p style={{ textAlign: 'center' }}>{data?.transaction_date ? makeLocalTime(data?.transaction_date) : null}</p>
      <br />
      <br />

      <Row gutter={10}>
        {dataSet?.map( (item,id) => (
            <Col xs={12} md={8} key={id}>
              <Card bordered={true} style={{  marginBottom: '10px' }}>
                <Statistic
                  title={<strong style={{ fontSize: '10px', color: '#000'}}>{item?.title}</strong>}
                  value={item?.value}
                  precision={2}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                  suffix="৳"
                />
                {item?.file ?
                  <Button size="small"
                    onClick={() => fileDownloader(item?.file)}
                    type="primary"
                    style={{ backgroundColor: '#019fb8', fontSize: '10px' }} icon={<DownloadOutlined />}>
                        ডাউনলোড
                  </Button>
                : 
                <Button size="small" disabled style={{ fontSize: '10px' }} icon={<DownloadOutlined />}>
                  ডাটা নেই
                </Button>}
              </Card>
            </Col>
          
        ))}
      </Row>
      {/* <table style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', }}>
        <tr>
          <td style={{ margin: '10px' }} >কাঁচামাল : </td>
          <td style={{ margin: '10px' }}> {data?.purchase_material} ৳ </td>
          {data?.purchase_material_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.purchase_material_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >অন্য উপাদানগুলো: </td>
          <td style={{ margin: '10px' }}> {data?.other_material} ৳ </td>
          
          {data?.other_material_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.other_material_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >প্রদেয়: </td>
          <td style={{ margin: '10px' }}> {data?.payable} ৳ </td>
          {data?.payable_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.payable_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >মোবাইল ব্যাংকিং : </td>
          <td style={{ margin: '10px' }}> {data?.mfs} ৳ </td>
          {data?.mfs_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.mfs_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >নগত : </td>
          <td style={{ margin: '10px' }}> {data?.cash} ৳ </td>
          {data?.cash_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.cash_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >সম্পদ : </td>
          <td style={{ margin: '10px' }}> {data?.asset} ৳ </td>
          {data?.asset_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.asset_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >ব্যাংক আমানত : </td>
          <td style={{ margin: '10px' }}> {data?.bank} ৳ </td>
          {data?.bank_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.bank_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >বেতন : </td>
          <td style={{ margin: '10px' }}> {data?.salary} ৳ </td>
          {data?.salary_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.salary_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >বোনাস : </td>
          <td style={{ margin: '10px' }}> {data?.bonus} ৳ </td>
          {data?.bonus_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.bonus_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >মজুরি : </td>
          <td style={{ margin: '10px' }}> {data?.wages} ৳ </td>
          {data?.wages_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.wages_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >যাতায়াত খরচ : </td>
          <td style={{ margin: '10px' }}> {data?.transport} ৳ </td>
          {data?.transport_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.transport_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >ইউটিলিটিস : </td>
          <td style={{ margin: '10px' }}> {data?.utility} ৳ </td>
          {data?.utility_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.utility_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >বিনোদন : </td>
          <td style={{ margin: '10px' }}> {data?.entertainment} ৳ </td>
          {data?.entertainment_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.entertainment_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
          
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >যোগাযোগ খরচ: </td>
          <td style={{ margin: '10px' }}> {data?.communication} ৳ </td>
          {data?.communicationFl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.communicationFl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
          
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >বিজ্ঞাপন খরচ : </td>
          <td style={{ margin: '10px' }}> {data?.advertisement} ৳ </td>
          {data?.advertisement_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.advertisement_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >মার্কেটিং খরচ : </td>
          <td style={{ margin: '10px' }}> {data?.marketing} ৳ </td>
          {data?.marketing_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.marketing_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >নমুনা খরচ : </td>
          <td style={{ margin: '10px' }}> {data?.sample} ৳ </td>
          {data?.sample_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.sample_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >ঋণের কিস্তি : </td>
          <td style={{ margin: '10px' }}> {data?.credit_amount} ৳ </td>
          {data?.credit_amount_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.credit_amount_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
      </table> */}
    </Content>

  );
};

export default connect( null, { fileDownloader }) (WithdrawDetails);

