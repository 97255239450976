import styled from "styled-components";
import  Card from "antd";
export const Wrapper = styled.div`
    padding: 20px;
`;
export const Title = styled.div`
    width: 100%;
    background-color: #3ab569;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
    text-align: center;
`;

export const FormStyle = styled.div `
    width: 100%;
    display: block;
    padding: 5px 0px;

`;
export const TitleH1 = styled.h1 `
font-size:20px;
font-weight: bolder;
color: #e74c3c;
margin: 2px 0px;

`;
export const CardTitle = styled.h1 `
font-size:17px;
font-weight:600;
color:#999999;

`;
export const SubTitle = styled.h2 `

font-size:16px;
font-weight:600;

@media only screen and (max-width: 600px) {
    
font-size:14px;
font-weight:400;
    
  }

`;
export const TitleN = styled.h1 `
text-align:center;

font-size:22px;
font-weight:800;


`;
export const DetailsMianDiv = styled.div `
text-align:center;
padding:5px;
width:100%;


`;
export const DetailsSubDiv = styled.div `
display:inline-block;
padding:5px;



`;

export const DetailsSubDiv1 = styled.div `
display:inline-block;
padding:5px;



`;
export const DetailsSubDiv2 = styled.div `
display:inline-block;
padding:5px;



`;
export const DetailsSubDiv3 = styled.div `
display:inline-block;

padding:5px;



`;
