import history from "../history";
import api from "./apis/index";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "../reducers/type";
import moment from "moment";

export const getWithdraws = (p, a, t, id, ds, de) => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    
    let response = null;
    response = await api(localStorage.getItem("token")).get(`operation/transaction?p=${p?p:''}&a=${a?a:""}&t=${t?t:""}&id=${id?id:""}&ds=${ds?ds:""}&de=${de?de:""}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const withdrawCreate = (
  account, data, purchaseMaterialFl, otherMaterialFl, payableFl, 
  mfsFl, cashFl, assetFl, bankFl, 
  salaryFl, bonusFl, wagesFl, transportFl,
  utilityFl, entertainmentFl, communicationFl, advertisementFl,
  marketingFl, sampleFl, intraTransferFl, creditAmountFl,
  ) => async (dispatch) => {
  dispatch({ type: LOADING });
  data.account = account;

  data.purchase_material = data.purchase_material ? data.purchase_material : 0;
  data.other_material = data.other_material ? data.other_material : 0;
  data.payable = data.payable ? data.payable : 0;
  data.mfs = data.mfs ? data.mfs : 0;
  data.cash = data.cash ? data.cash : 0;
  data.asset = data.asset ? data.asset : 0;
  data.bank = data.bank ? data.bank : 0;
  data.salary = data.salary ? data.salary : 0;
  data.bonus = data.bonus ? data.bonus : 0;
  data.wages = data.wages ? data.wages : 0;
  data.transport = data.transport ? data.transport : 0;
  data.utility = data.utility ? data.utility : 0;
  data.entertainment = data.entertainment ? data.entertainment : 0;
  data.communication = data.communication ? data.communication : 0;
  data.advertisement = data.advertisement ? data.advertisement : 0;
  data.marketing = data.marketing ? data.marketing : 0;
  data.sample = data.sample ? data.sample : 0;
  if(data.intra_transfer_from) {
    data.intra_transfer_from = data.intra_transfer_from;
  }
  if(data.intra_transfer_to) {
    data.intra_transfer_to = data.intra_transfer_to;
  }
 data.credit_amount = data.credit_amount ? data.credit_amount : 0;

  if (purchaseMaterialFl) data.purchase_material_fl = purchaseMaterialFl;
  if (otherMaterialFl) data.other_material_fl = otherMaterialFl;
  if (payableFl) data.payable_fl = payableFl;
  if (mfsFl) data.mfs_fl = mfsFl;
  if (cashFl) data.cash_fl = cashFl;
  if (assetFl) data.asset_fl = assetFl;
  if (bankFl) data.bank_fl = bankFl;
  if (salaryFl) data.salary_fl = salaryFl;
  if (bonusFl) data.bonus_fl = bonusFl;
  if (wagesFl) data.wages_fl = wagesFl;
  if (transportFl) data.transport_fl = transportFl;
  if (utilityFl) data.utility_fl = utilityFl;
  if (entertainmentFl) data.entertainment_fl = entertainmentFl;
  if (communicationFl) data.communication_fl = communicationFl;
  if (advertisementFl) data.advertisement_fl = advertisementFl;
  if (marketingFl) data.marketing_fl = marketingFl;
  if (sampleFl) data.sample_fl = sampleFl;
  if (intraTransferFl) data.intra_transfer_fl = intraTransferFl;
  if (creditAmountFl) data.credit_amount_fl = creditAmountFl;
  
  try{
    const response = await api(localStorage.getItem("token")).post('/operation/transaction/withdraw', data);
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Withdraw successfully done!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const uploadFile = (val) => async (dispatch) => {
  if (val)
  dispatch({ type: LOADING });
//   let file = null

//   if(data.file !== undefined)
//     if(data.file.fileList.length > 0){
//       Object.keys(data).map((ele) => {
      
//           if(typeof(data[ele]) === 'object'){
  
//               Object.keys(data[ele]).map((last,id) => {
//                 if(last === 'fileList'){
//                   file=data[ele][last][0].response.name
                
//                   }
//               })
//             }
          
//       });
// }
// console.log(data)
  // if (data.sale_fl) data.sale_fl = data.sale_fl.file.name;
  // if (data.grant_financing_fl) data.grant_financing_fl = data.grant_financing_fl.file.name;
  // if (data.other_fl) data.other_fl = data.other_fl.file.name;
  // if (data.receivable_fl) data.receivable_fl = data.receivable_fl.file.name;
  // if (data.owner_equity_fl) data.owner_equity_fl = data.owner_equity_fl.file.name;
  // if (data.in_kind_fl) data.in_kind_fl = data.in_kind_fl.file.name;
  // if (data.cash_fl) data.cash_fl = data.cash_fl.file.name;
  // if (data.mfs_fl) data.mfs_fl = data.mfs_fl.file.name;
  // if (data.bank_fl) data.bank_fl = data.bank_fl.file.name;
  const data = new FormData();
  data.append("file", val);
  try{
    const response = await api(localStorage.getItem("token")).post('operation/file/upload', data);
    
    if(response.status === 201 ){
        dispatch({type: LOADED})
        return response
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};


export const removeFile = (name) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).patch(`/operation/file/delete?file_name=${name}`);
    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const fileDownloader = (name) => async (dispatch) => {
  dispatch({ type: LOADING });
  const ext = name.split(".")[1]
  try{
    const response = await api(localStorage.getItem("token")).get(`operation/file/download?file_name=${name}`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${name}.${ext}`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err?.response?.status === 451)
          {
            history.push('/pending-verification')
          }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}