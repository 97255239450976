import { Button, Form, Modal, Row, Col, Breadcrumb, Select, Input, InputNumber } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import {  productList } from "../../actions/operation";
import {  depositeCreate, removeFile, uploadFile } from "../../actions/deposite";
import { numberInput, renderTextField } from "../../components/form";
import history from "../../history";
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import { EyeFilled, EyeOutlined, HomeOutlined, RollbackOutlined, UploadOutlined } from "@ant-design/icons";
import './style.css';
import { FcEmptyTrash } from "react-icons/fc";
import { TbCurrencyTaka } from "react-icons/tb";

const { Option } = Select;

const DepositeMoney = ({ removeFile, depositeCreate, uploadFile, location ,productList}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasProduct, setHasProduct] = useState(false);
  const [porducts, setPorducts] = useState([]);
  const [selectedproduct, setSelectedProduct] = useState([]);
  const [productArray, setProductArray] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [account, setAccount] = useState([]);
  const [saleFl, setSaleFl] = useState(null);
  const [receivedFromSaleFl, setReceivedFromSaleFl] = useState(null);
  const [grantFinancingFl, setGrantFinancingFl] = useState(null);
  const [otherFl, setOtherFl] = useState(null);
  const [receivableFl, setReceivableFl] = useState(null);
  const [ownerEquityFl, setOwnerEquityFl] = useState(null);
  const [inKindFl, setInKindFl] = useState(null);
  const [grandInventoryFl, setGrandInventoryFl] = useState(null);
  const [cashFl, setCashFl] = useState(null);
  const [mfsFl, setMfsFl] = useState(null);
  const [bankFl, setBankFl] = useState(null);
  const [salePrice, setSalePrice] = useState(0);
  const [totalDeposte, setTotalDeposte] = useState({
    sale: 0,
    received_from_sale: 0,
    grant_financing: 0,
    received_from_sale: 0,
    receivable: 0,
    owner_equity: 0,
    // in_kind: 0,
    other: 0,
    cash: 0,
    mfs: 0,
    bank: 0,
  });
  const [totalEntry, setTotalEntry] = useState({
    cash: 0,
    mfs: 0,
    bank: 0,
  });
  const [total,setTotal]=useState(0);
  const [rest,setRest]=useState(0);
  const [form] = Form.useForm();
  const [qform] = Form.useForm();
  useEffect(async() => {
    window.scrollTo(0, 0)
    const id = new URLSearchParams(location.search).get("id");
    setAccount(id);
    const res =  await productList(id);
    if(res?.success) {
      setAllProducts(res?.product);
      if (res?.product?.length === 0) {
        setHasProduct(false);
      }
      else{
        setHasProduct(true);
      }
    }
    form.setFieldsValue({
      // sale: 0,
      // received_from_sale: 0,
      // grant_financing: 0,
      // receivable: 0,
      // owner_equity: 0,
      // in_kind: 0,
      // cash: 0,
      // mfs: 0,
      // bank: 0,
      // other: 0,
    })
  }, [])
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFileUplaod = async (e, filedName) => {
    let img = null;
    let nam = e.target.name;
    let val = e.target.value;
      var type= e.target.files[0].type;
      if (e.target.files && e.target.files[0]) {
        var fsize = e.target.files[0].size/1024;
          // if( type == 'image/jpg' || type == 'image/jpeg' || type == 'image/JPG' || type == 'image/JPEG' || type == 'image/PNG' || type == 'image/png' ){
          if( type ){
          
              try {
                  Resizer.imageFileResizer  (
                  e.target.files[0],
                  600,
                  400,
                  'PNG',
                  100,
                  0,
                  uri =>  {
                    const a = async () => {
                      
                      const res = await uploadFile(uri)
                      if (filedName === 'sale_fl') setSaleFl(res?.data?.name)
                      if (filedName === 'received_from_sale_fl') setSaleFl(res?.data?.name)
                      if (filedName === 'grant_financing_fl') setGrantFinancingFl(res?.data?.name)
                      if (filedName === 'receivable_fl') setReceivableFl(res?.data?.name)
                      if (filedName === 'owner_equity_fl') setOwnerEquityFl(res?.data?.name)
                      if (filedName === 'in_kind_fl') setInKindFl(res?.data?.name)
                      if (filedName === 'grant_inventory_fl') setGrandInventoryFl(res?.data?.name)
                      if (filedName === 'cash_fl') setCashFl(res?.data?.name)
                      if (filedName === 'mfs_fl') setMfsFl(res?.data?.name)
                      if (filedName === 'bank_fl') setBankFl(res?.data?.name)
                      if (filedName === 'other_fl') setOtherFl(res?.data?.name)
                    }
                    a();
                  },
                  'blob',
                  600,
                  400,
                  );
                  }   
                  catch(err) {
                  }
          }
          else{
  
              alert('Invalid image format');
              return true;
          }
          if(img){
            const res = await uploadFile(img)
          }
      }
  // if(filedName === 'sale_fl'){
  //   setSaleFl()
  // }
  // console.log(e)
  }
  const handleDeleteFile = async (name, fieldName) => {
    
    const res = await removeFile(name);
    if (res) {

      if (fieldName === 'sale_fl') setSaleFl(null)
      if (fieldName === 'received_from_sale_fl') setReceivedFromSaleFl(null)
      if (fieldName === 'grant_financing_fl') setGrantFinancingFl(null)
      if (fieldName === 'receivable_fl') setReceivableFl(null)
      if (fieldName === 'owner_equity_fl') setOwnerEquityFl(null)
      if (fieldName === 'in_kind_fl') setInKindFl(null)
      if (fieldName === 'grant_inventory_fl') setGrandInventoryFl(null)
      if (fieldName === 'cash_fl') setCashFl(null)
      if (fieldName === 'mfs_fl') setMfsFl(null)
      if (fieldName === 'bank_fl') setBankFl(null)
      if (fieldName === 'other_fl') setOtherFl(null)
      
      // if (fieldName === 'sale_fl') {
      //   form.setFieldsValue({
      //     sale_fl: '',
      //   })
      // }
    }
  }
 
  const onFinish = async (values) => {
    const res = await depositeCreate(
      account, 
      productArray, 
      values, saleFl, receivedFromSaleFl, grantFinancingFl, 
      otherFl, receivableFl, ownerEquityFl,
      inKindFl, grandInventoryFl, cashFl, mfsFl, bankFl,
      )
    if (res === 201) {
      history.goBack();
    }
  };


  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const handleProductDeSelect = (e) => { 
    const arr = productArray.filter(item => item?.id !== e);
    setProductArray(arr);
    let oldPrice = salePrice;
    const selectedItem = allProducts.filter((item, i) => item?.id === e);
    const selectedQty = productArray.filter((item, i) => item?.id === e);
    const price = selectedItem[0]?.sale_price;
    const qnty = selectedQty[0]?.quantity;
    const finalPrice = oldPrice - (price * Number(qnty))

    const newValues = {
      ...totalDeposte,
      ['sale']: finalPrice
  } 
    setTotalDeposte(newValues)
    const { 
      received_from_sale, grant_financing, receivable, owner_equity, other
    } = totalDeposte;
    const newTotal = parseInt(received_from_sale) + parseInt(grant_financing)
    + parseInt(receivable) + parseInt(owner_equity) + parseInt(other) 
    setTotal(newTotal)


    setSalePrice(finalPrice);
    
    const oldSelected = selectedproduct.filter(item => item?.id !== e);
    setSelectedProduct(oldSelected);

    form.setFieldsValue({
      sale: finalPrice,
    })
  }

  const handleProduct = (e) => {
    setPorducts(e)
    if(productArray.length < e.length){
        showModal(true)
    }
  }

  const onFinishProduct = async (values) => {
    if(productArray?.length < porducts?.length) {
        const val = values?.quantity;
        productArray.push(
            {'id': porducts[porducts?.length -1], 'quantity': val}
        )
        
      const selectedItem = allProducts.filter((item, i) => item?.id === porducts[porducts?.length -1]);
      let price = salePrice;
      const finalPrice = price + (selectedItem[0].sale_price * Number(values?.quantity))

    //   const newValues = {
    //     ...totalDeposte,
    //     ['sale']: finalPrice
    // } 
    //   setTotalDeposte(newValues)
    //   const { 
    //     sale, received_from_sale, grant_financing, receivable, owner_equity, in_kind, other
    //    } = totalDeposte;
    //   const newTotal = parseInt(finalPrice) + parseInt(received_from_sale) + parseInt(grant_financing)
    //   + parseInt(receivable) + parseInt(owner_equity)
    //   + parseInt(in_kind) + parseInt(other) 
    //   setTotal(newTotal)

      setSalePrice(finalPrice)
      setProductArray([...productArray])

      const oldSelected = selectedproduct;
      oldSelected.push(
        {id: selectedItem[0].id, name: selectedItem[0].name, quantity: val, unitPrice: selectedItem[0].sale_price, totalPrice: selectedItem[0].sale_price * Number(values?.quantity)}
      )
      setSelectedProduct(oldSelected)
      
    form.setFieldsValue({
      sale: finalPrice,
    })
  }
    qform.resetFields();
    handleCancel();
  }


  const calc_total = (newValues) => {
    const { 
      sale, received_from_sale, grant_financing, receivable, owner_equity, other
      } = newValues;
    const newTotal = parseInt(sale) + parseInt(received_from_sale) + parseInt(grant_financing)
    + parseInt(receivable) + parseInt(owner_equity)
    + parseInt(other) 
    setTotal(newTotal)
  } 
  
  const calc_remove_total = (newValues) => {
    const { cash, mfs, bank
      } = newValues;
    const newTotal =  parseInt(cash) + parseInt(mfs) + parseInt(bank)
    setRest(newTotal)
  }

  const handleTotalDeposite = (e, name) => {
    let value= e;
    const newValues = {
      ...totalDeposte,
      [name]: value ? value : 0 
    } 
    setTotalDeposte(newValues)
    calc_total(newValues) 
  }
  
  const handleRemoveDeposite = (e, name) => {
    let value= e;
    const newValues = {
      ...totalEntry,
      [name]: value ? value : 0 
    } 
    setTotalEntry(newValues)
    calc_remove_total(newValues) 
  }


  const fileds = [
    {
      type: 'input',
      name: 'sale',
      placeholder: 'সর্বোমট বিক্রি',
      // valfnc: (e) => handleTotalDeposite(e, 'sale'),
    },
    {
      type: 'file',
      name: 'sale_fl',
      placeholder: 'ফাইল',
      imageName: saleFl,
      fnc: (e) => handleFileUplaod(e, 'sale_fl'),
    },
    {
      type: 'input',
      name: 'received_from_sale',
      placeholder: 'বিক্রয় থেকে সংগ্রহ',
      valfnc: (e) => handleTotalDeposite(e, 'received_from_sale'),
    },
    // {
    //   type: 'file',
    //   name: 'received_from_sale_fl',
    //   placeholder: 'ফাইল',
    //   imageName: receivedFromSaleFl,
    //   fnc: (e) => handleFileUplaod(e, 'received_from_sale_fl'),
    // },
    {
      type: 'input',
      name: 'grant_financing',
      placeholder: 'অনুদান (টাকা)',
      valfnc: (e) => handleTotalDeposite(e, 'grant_financing'),
    },
    {
      type: 'file',
      name: 'grant_financing_fl',
      placeholder: 'ফাইল',
      imageName: grantFinancingFl,
      fnc: (e) => handleFileUplaod(e, 'grant_financing_fl'),
    },
    {
      type: 'input',
      name: 'receivable',
      placeholder: 'পাওনা (বিক্রয়)',
      valfnc: (e) => handleTotalDeposite(e, 'receivable'),
    },
    {
      type: 'file',
      name: 'receivable_fl',
      placeholder: 'ফাইল',
      imageName: receivableFl,
      fnc: (e) => handleFileUplaod(e, 'receivable_fl'),
    },
    {
      type: 'input',
      name: 'owner_equity',
      placeholder: 'নিজের জমা',
      valfnc: (e) => handleTotalDeposite(e, 'owner_equity'),
    },
    {
      type: 'file',
      name: 'owner_equity_fl',
      placeholder: 'ফাইল',
      imageName: ownerEquityFl,
      fnc: (e) => handleFileUplaod(e, 'owner_equity_fl'),
    },
    {
      type: 'input',
      name: 'in_kind',
      placeholder: 'অনুদানকৃত সম্পদ',
      // valfnc: (e) => handleTotalDeposite(e, 'in_kind'),
    },
    {
      type: 'file',
      name: 'in_kind_fl',
      placeholder: 'ফাইল',
      imageName: inKindFl,
      fnc: (e) => handleFileUplaod(e, 'in_kind_fl'),
    },
    {
      type: 'input',
      name: 'grant_inventory',
      placeholder: 'অনুদানকৃত মালামাল',
      // valfnc: (e) => handleTotalDeposite(e, 'grant_inventory'),
    },
    {
      type: 'file',
      name: 'grant_inventory_fl',
      placeholder: 'ফাইল',
      imageName: grandInventoryFl,
      fnc: (e) => handleFileUplaod(e, 'grant_inventory_fl'),
    },
    {
      type: 'input',
      name: 'other',
      placeholder: 'লভ্যাংশ/ সুদ/ কমিশন থেকে প্রাপ্ত',
      valfnc: (e) => handleTotalDeposite(e, 'other'),
    },
    {
      type: 'file',
      name: 'other_fl',
      placeholder: 'ফাইল',
      imageName: otherFl,
      fnc: (e) => handleFileUplaod(e, 'other_fl'),
    },
    {
      type: 'input',
      name: 'cash',
      placeholder: 'হাতে নগত জমা',
      valRfnc: (e) => handleRemoveDeposite(e, 'cash'),
    },
    {
      type: 'file',
      name: 'cash_fl',
      placeholder: 'ফাইল',
      imageName: cashFl,
      fnc: (e) => handleFileUplaod(e, 'cash_fl'),
    },
    {
      type: 'input',
      name: 'mfs',
      placeholder: 'মোবাইলের মাধ্যমে জমা',
      valRfnc: (e) => handleRemoveDeposite(e, 'mfs'),
    },
    {
      type: 'file',
      name: 'mfs_fl',
      placeholder: 'ফাইল',
      imageName: mfsFl,
      fnc: (e) => handleFileUplaod(e, 'mfs_fl'),
    },
    {
      type: 'input',
      name: 'bank',
      placeholder: 'ব্যাংকের মাধ্যমে জমা',
      valRfnc: (e) => handleRemoveDeposite(e, 'bank'),
    },
    {
      type: 'file',
      name: 'bank_fl',
      placeholder: 'ফাইল',
      imageName: bankFl,
      fnc: (e) => handleFileUplaod(e, 'bank_fl'),
    },
  ];
  return(
    <Content style={{ backgroundColor: '#ecf0f1'}}>
        
        <Breadcrumb
          items={[
            {
              title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
            },
            {
              title: "টাকা জমা",
            },
            {
              title: (<RollbackOutlined onClick={() => history.goBack()} />),
            },
          ]}
        />
      <br />
      <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          form={form}
          autoComplete={false}
          requiredMark={true}
          colon={false}
          layout="vertical"
          style={{ backgroundColor: '#FFF', padding: '10px'}}
        >
        <Row gutter={0}>
          {hasProduct ? 
            <>
              <Col xs={24} lg={24} md={24} sm={24}> 
                <Form.Item
                    name="product"
                    style={{ width: '100%' }}
                    label="প্রোডাক্ট সিলেক্ট করুন"
                    rules={[
                      { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                    ]}
                >
                  <Select
                    placeholder="প্রোডাক্ট সিলেক্ট করুন"
                    size="middle"
                    allowClear
                    style={{width:'99%'}}
                    mode="multiple"
                    onDeselect={(e) => handleProductDeSelect(e)}
                    onChange={(e) => handleProduct(e)}
                  >
                    {allProducts?.map(item => (
                        <Option value={item.id}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          :null}
          <Col xs={24}>
            {
              selectedproduct.length > 0 ? 
                <Row gutter={0}>
                  <Col xs={6} style={{ textAlign: 'center' }}>প্রোডাক্টের নাম</Col>
                  <Col xs={6} style={{ textAlign: 'center' }}>পরিমান</Col>
                  <Col xs={6} style={{ textAlign: 'center' }}>ইউনিট প্রাইস</Col>
                  <Col xs={6} style={{ textAlign: 'center' }}>মোট প্রাইস</Col>
                  {selectedproduct?.map((item, i) => (
                    <>
                      <Col xs={6} style={{ textAlign: 'center' }}>{item?.name}</Col>
                      <Col xs={6} style={{ textAlign: 'center' }}>{item?.quantity}</Col>
                      <Col xs={6} style={{ textAlign: 'center', }}>{item?.unitPrice}</Col>
                      <Col xs={6} style={{ textAlign: 'center' }}>{item?.totalPrice}</Col>
                    </>
                  ))}
                  <Col xs={6}  offset={18} style={{ textAlign: 'center', borderTop: '1px solid gray' }}>{salePrice}</Col>
                </Row>
              : null
            }
        </Col>
        
        <Col xs={24}> <br /> </Col>
        <Row gutter={20}>
            {fileds?.map(item => (
              item.type === 'file'
              ? 
                <>
                  <Col xs={8} md={4}>
                    <Form.Item
                        name={item?.name}
                        style={{ width: '100%' }}
                        label="&nbsp;"
                    >
                      <div class="upload-btn-wrapper">
                        <button class="btn" ><UploadOutlined /> &nbsp;আপ্লোড</button>
                        <Input id="actual-btn" type="file" name={item?.name} onChange={(e)=>item?.fnc(e)}/>
                      
                      </div>
                        {item?.imageName ? 
                        <p style={{ fontSize: "10px" }} onClick={() => handleDeleteFile(item?.imageName, item?.name)}><FcEmptyTrash /> ফাইলটি রিমুভ করুন</p>
                        :
                        null
                      }
                    </Form.Item>
                  </Col>
                </>
              :
                item?.valfnc ?
                  <Col xs={16} md={8}>
                    <Form.Item
                        label={item?.placeholder}
                        name={item?.name}
                        rules={[
                          { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                        ]}
                        >
                        <InputNumber onChange={(e) => item?.valfnc(e)} name={item?.name} min={0} placeholder={item?.placeholder} type='number' addonAfter={<TbCurrencyTaka />} />
                    </Form.Item>
                  </Col>
                :
                item?.name === 'cash' ?
                    <>
                    <Col xs={24}>
                      {(total !== 0 && rest === total) ? 
                      <p style={{ textAlign: 'center', borderBottom: '1px solid lightgray', fontSize: '16px', fontWeight: 'bold'}}>
                        জমা <strong style={{color: 'green', fontSize: '18px',}}>{total - rest}({total})</strong> টাকা মিলাতে হবে
                      </p>
                      :
                      <p style={{ textAlign: 'center', borderBottom: '1px solid lightgray', fontSize: '16px', fontWeight: 'bold'}}>
                        জমা <strong style={{color: 'red', fontSize: '18px',}}>{total - rest}({total})</strong> টাকা মিলাতে হবে
                      </p>
                      }<br />
                    </Col>
                    <Col xs={16} md={8}>
                      
                      <Form.Item
                          label={item?.placeholder}
                          name={item?.name}
                          rules={[
                            { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                          ]}
                          >
                          <InputNumber onChange={(e) => item?.valRfnc(e)} name={item?.name} min={0} placeholder={item?.placeholder} type='number' addonAfter={<TbCurrencyTaka />} />
                      </Form.Item>
                    </Col> 
                  </>
                  :
                  <Col xs={16} md={8}>
                    <Form.Item
                        label={item?.placeholder}
                        name={item?.name}
                        rules={[
                          { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                        ]}
                        >
                        <InputNumber disabled={(item?.name === 'sale' && allProducts.length > 0) ? true : false} onChange={(e) => (item?.name === 'sale' || item?.name === 'in_kind' || item?.name === 'grant_inventory') ? null : item?.valRfnc(e)} name={item?.name} min={0} placeholder={item?.placeholder} type='number' addonAfter={<TbCurrencyTaka />} />
                    </Form.Item>
                  </Col> 
            ))} 
            </Row>
                <Col xs={24} md={12}>
                  {renderTextField('note', 'Note', [
                    { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
                  ], '', 'small')} 
                </Col> 
            <Col xs={24}><br /></Col>
            <Col xs={24}>
                <Form.Item>
                <Button type="primary" htmlType="submit" disabled={( rest !== total) ? true : false}>
                দাখিল করুন
                </Button>
                </Form.Item>
            </Col>
        </Row>
      </Form>

      <Modal closeIcon={<EyeOutlined style={{ display: 'none' }} />} icon = {<EyeFilled/>} maskClosable={false} closeButton={null} keyboard={false} footer={false} title="প্রোডাক্টের পরিমান" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
            name="basic"
            labelCol={{
            span: 8,
            }}
            wrapperCol={{
            span: 16,
            }}
            style={{
            maxWidth: 600,
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinishProduct}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={qform}
        >
              {numberInput('quantity', 'পরিমান', [
                { required: false, message: "ফিল্ডটি  অবশ্যই পূরণ করতে হবে!" },
              ], '', 'middle')}
            <Form.Item
            wrapperCol={{
                offset: 8,
                span: 16,
            }}
            >
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
            </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
};

export default connect(null,{ productList, depositeCreate, uploadFile, removeFile })(DepositeMoney);
