import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col,Card,Button, Empty  } from "antd";
import {
  LikeOutlined,InfoCircleOutlined, CustomerServiceOutlined
 
} from "@ant-design/icons";
import history from "../../history";

import { CardTitle, SubTitle, TitleH1 } from "./Style";
import CreateSubOrganization from "../enterprice/CreateSubOrganization";
import './style.css';
import { operationDetail } from "../../actions/operation";
import { connect } from "react-redux";
import ForwardFloatButton from "antd/es/float-button/FloatButton";
import { FcShop,FcBusinessContact } from "react-icons/fc";
const Dashboard = ({ operationDetail }) => {
  const [data, setData] = useState([]);
  const [access, setAccess] = useState('');
  const [createSubOrg, setCreateSubOrg] = useState(false);
  
  useEffect(async () =>  {
    window.scrollTo(0, 0)
    if(!localStorage.getItem("token") ||
      localStorage.getItem("token") === null){
      localStorage.clear();
      history.replace("/login");
    }
    const res =  await operationDetail();
    setData(res)
    setAccess(JSON.parse(localStorage.getItem('access')))

  }, []);


  const createorg = () => {
    setCreateSubOrg(true);
  };
  return (
    <Content> 
      <TitleH1> স্বাগতম </TitleH1>
      <SubTitle> 
        {data?.enterprise !== null ? 
          data?.enterprise?.name 
          : 
          <Button
            onClick={() => history.push('/tickets')}
            shape="square"
            danger
            type="primary"
            style={{ height: 'auto', width: '100%'}}
          >
           <p>আপনার এন্টারপ্রাইজ খুজে পাওয়া যায় নি।</p>
           <p>দয়া করে অথোরিটির সাথে যোগাযোগ করুন !</p>
          </Button> 
        } 
      </SubTitle>
        <br />
        {data?.enterprise !== null ? 
        <Row gutter={20}>
        <Col xs={12} md={16} lg={16}>
          <Card hoverable style={{ backgroundColor: "#ffff",fontSize:'10px', minHeight: '160px' }} className="panel">
            <FcShop className="cardLogo" />
            <CardTitle>ব্যবসায়িক একাউন্টের সংখ্যা</CardTitle>
            <CardTitle style={{ fontWeight: 'bolder'}}>{data?.account?.length}</CardTitle>
          
          </Card>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250" style={{position: 'relative', top: '-70px'}}>
          <path fill="#0099ff" fill-opacity="1" d="M0,192L48,186.7C96,181,192,171,288,176C384,181,480,203,576,192C672,181,768,139,864,112C960,85,1056,75,1152,96C1248,117,1344,171,1392,197.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
          </svg> */}
        </Col>
        {/* <Col xs={0} md={8} lg={8}></Col> */}
        {localStorage.getItem('enterprise_admin') === 'true' ?
          <Col xs={12} md={8} lg={8}>
            <Card hoverable style={{ backgroundColor: "#ffff",fontSize:'10px', minHeight: '160px' }} className="panel">
              <FcBusinessContact className="cardLogo" />
              <CardTitle>হিসাবরক্ষণকারী</CardTitle>
              <Button size="small" type="primary" onClick={() => history.push('/user-details')}>বিস্তারিত</Button>
            </Card>
          </Col>
        : null}
        </Row>
     :null}
      <br />
      {data?.account ?  
        <Row gutter={20}>
          {data?.account?.map( item => (
            <Col xs={12}>
              
              <Card className="cardColor" onClick={() => history.push(`/enterprise?id=${item?.id}`)} style={{ fontWeight: 'bold', margin: '5px 0px'}}>
                <Row  gutter={20}> 
                  <Col xs={24}>
                    <Row  gutter={20}>
                      <Col xs={24} style={{fontSize:'20px'}}>
                        <p className="nameLogo"> <span className="nameLogoInner">{(item?.name).charAt(0)}</span></p>
                      </Col>
                      <Col xs={24} style={{fontSize:'18px', textAlign: 'center'}}>{item?.name}</Col>
                      {/* <Col xs={24} style={{color:'#000'}}>{item?.address}</Col>
                      <Col xs={24}>{item?.mobile_number}</Col>
                      <Col xs={24}>{item?.email} </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
              
          ))}
          
          {/* <Col xs={24} lg={24} md={24} sm={24} style={{ marginTop: "15px" }}>
          <Button
            key="1"
            type="primary"
            style={{width:'100%'}}
            
            onClick={() => createorg()}
          >
            নতুন একাউন্ট তৈরি করুন
          </Button>
          {createSubOrg? 
            <CreateSubOrganization
              title="নতুন অ্যাকাউন্ট"
              visible={createSubOrg}
              cancel={() => setCreateSubOrg(false)}
              ok={() => setCreateSubOrg(false)}
            />
          :null}
            
          </Col> */}
        </Row>
      : <Empty />}
      <ForwardFloatButton
        onClick={() => history.push('/tickets')}
        shape="square"
        danger
        type="primary"
        style={{
          right: 24,
          bottom: 20,
        }}
        icon={<CustomerServiceOutlined />}
      />
    </Content>
   
  );
};

export default connect(null, {operationDetail})(Dashboard);
