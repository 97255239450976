import history from "../history";
import api from "./apis/index";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "../reducers/type";
import moment from "moment";

export const getDeposits = (p, a, t, id, ds, de) => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    
    let response = null;
    response = await api(localStorage.getItem("token")).get(`operation/transaction?p=${p?p:''}&a=${a?a:""}&t=${t?t:""}&id=${id?id:""}&ds=${ds?ds:""}&de=${de?de:""}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const depositeCreate = (
  account, product, data, saleFl,
  receivedFromSaleFl, grantFinancingFl, 
  otherFl, receivableFl, ownerEquityFl,
  inKindFl, grandInventoryFl, cashFl, mfsFl, bankFl,
  ) => async (dispatch) => {
  dispatch({ type: LOADING });
  data.account = account;
  data.product = product;

  data.sale = data.sale ? data.sale : 0;
  data.received_from_sale = data.received_from_sale ? data.received_from_sale : 0;
  data.grant_financing = data.grant_financing ? data.grant_financing : 0;
  data.other = data.other ? data.other : 0;
  data.receivable = data.receivable ? data.receivable : 0;
  data.owner_equity = data.owner_equity ? data.owner_equity : 0;
  data.in_kind = data.in_kind ? data.in_kind : 0;
  data.grant_inventory = data.grant_inventory ? data.grant_inventory : 0;
  data.cash = data.cash ? data.cash : 0;
  data.mfs = data.mfs ? data.mfs : 0;
  data.bank = data.bank ? data.bank : 0;

  if (saleFl) data.sale_fl = saleFl;
  if (grantFinancingFl) data.grant_financing_fl = grantFinancingFl;
  if (otherFl) data.other_fl = otherFl;
  if (receivableFl) data.receivable_fl = receivableFl;
  if (ownerEquityFl) data.owner_equity_fl = ownerEquityFl;
  if (inKindFl) data.in_kind_fl = inKindFl;
  if (grandInventoryFl) data.grant_inventory_fl = grandInventoryFl;
  if (mfsFl) data.mfs_fl = mfsFl;
  if (bankFl) data.bank_fl = bankFl;
  if (cashFl) data.cash_fl = cashFl;
  
  try{
    const response = await api(localStorage.getItem("token")).post('/operation/transaction/deposit', data);
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Deposite successfully done!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const uploadFile = (val) => async (dispatch) => {
  if (val)
  dispatch({ type: LOADING });
//   let file = null

//   if(data.file !== undefined)
//     if(data.file.fileList.length > 0){
//       Object.keys(data).map((ele) => {
      
//           if(typeof(data[ele]) === 'object'){
  
//               Object.keys(data[ele]).map((last,id) => {
//                 if(last === 'fileList'){
//                   file=data[ele][last][0].response.name
                
//                   }
//               })
//             }
          
//       });
// }
// console.log(data)
  // if (data.sale_fl) data.sale_fl = data.sale_fl.file.name;
  // if (data.grant_financing_fl) data.grant_financing_fl = data.grant_financing_fl.file.name;
  // if (data.other_fl) data.other_fl = data.other_fl.file.name;
  // if (data.receivable_fl) data.receivable_fl = data.receivable_fl.file.name;
  // if (data.owner_equity_fl) data.owner_equity_fl = data.owner_equity_fl.file.name;
  // if (data.in_kind_fl) data.in_kind_fl = data.in_kind_fl.file.name;
  // if (data.cash_fl) data.cash_fl = data.cash_fl.file.name;
  // if (data.mfs_fl) data.mfs_fl = data.mfs_fl.file.name;
  // if (data.bank_fl) data.bank_fl = data.bank_fl.file.name;
  const data = new FormData();
  data.append("file", val);
  try{
    const response = await api(localStorage.getItem("token")).post('operation/file/upload', data);
    
    if(response.status === 201 ){
        dispatch({type: LOADED})
        return response
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};


export const removeFile = (name) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).patch(`/operation/file/delete?file_name=${name}`);
    if(response.status === 201 ) {
      dispatch({ type: LOADED });
      return response;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const fileDownloader = (name) => async (dispatch) => {
  console.log(name);
  dispatch({ type: LOADING });
  const ext = name.split(".")[1]
  try{
    const response = await api(localStorage.getItem("token")).get(`operation/file/download?file_name=${name}`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${name.split(".")[0]}.${ext}`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err?.response?.status === 451)
          {
            history.push('/pending-verification')
          }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}