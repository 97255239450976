import axios from 'axios';

export default (token = false) => {
     
    
    return axios.create({
        baseURL: 'https://api-ams.redorch.com/enterprise/v1/',
        headers: {
            'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })
}