import history from "../history";
import api from "./apis/index";
import {
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR,
} from "../reducers/type";
import moment from "moment";

export const getLoan = (a,l,ls,s,ds,de) => async (dispatch) => {
  dispatch({ type: LOADING });

  try{
    
    let response = null;
    response = await api(localStorage.getItem("token")).get(`/loan?a=${a?a:""}&l=${l?l:""}&ls=${ls?ls:""}&s=${s?s:""}&ds=${ds?ds:""}&de=${de?de:""}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getLoanDetails = (id, loan) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`/loan/detail?account=${id}&loan=${loan}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data?.detail;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const LoanCreate = (val, img, account) => async (dispatch) => {
  dispatch({ type: LOADING });

  const data = new FormData();
  data.append("account", account);
  data.append("name", val.name);
  data.append("mobile_number", val.mobile_number);
  data.append("type", val.type);
  data.append("state", val.state);
  if (val.date) data.append("date", moment(val.date).toISOString());
  data.append("amount", val.amount);
  data.append("payment_method", val.payment_method);
  if (val?.note) data.append("note", val.note);
  if (val?.img) data.append("loan_doc", img);
  try{
    const response = await api(localStorage.getItem("token")).post('/loan/create', data,
    {
      headers: {
        "content-type": "multipart/form-data",
        'authorization': `Bearer ${localStorage.getItem("token")}`
      },
    });
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Loan successfully created!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const LoanPay = (img, val, account, loan) => async (dispatch) => {
  dispatch({ type: LOADING });

  const data = new FormData();
  data.append("loan", loan);
  data.append("account", account);
  if (val.date) data.append("date", moment(val.date).toISOString());
  data.append("amount", val.amount);
  data.append("payment_method", val.payment_method);
  if (val.note) data.append("note", val.note);
  if (val.img) data.append("loan_doc", img);
  try{
    const response = await api(localStorage.getItem("token")).patch('/loan/pay', data);
    
    if(response.status === 201 ){
        dispatch({ type: SUCCESS_MESSAGE, payload: 'Loan successfully paid!' });
        dispatch({type: LOADED})
        return response.status
    }
  }
  catch( err ){
    // console.log(err)
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

export const getLoanStat = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    
    const response = await api(localStorage.getItem("token")).get(`/loan/state?account=${id}`);
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      return response.data;
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}


export const loanFileDownloader = (name) => async (dispatch) => {
  dispatch({ type: LOADING });
  const ext = name.split(".")[1]
  try{
    const response = await api(localStorage.getItem("token")).get(`operation/file/download?file_name=${name}`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `FileName.${ext}`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err?.response?.status === 451)
          {
            history.push('/pending-verification')
          }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}