import styled, { createGlobalStyle } from "styled-components";
    const GlobalStyle = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        font-family: 'Roboto', 'Mulish', sans-serif;
        
    }
    button{
        }

`;


export const Container = styled.div`
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${props => props.name === 'detail' ? '10px 150px' : '10px 20px'};
    
    @media only screen and (max-width: 600px) {
        
    padding: ${props => props.name === 'detail' ? '10px 20px' : '10px 20px'};
    }
`;

export const Wrapper = styled.div`
    padding: 20px;
`;

export const Title = styled.div`
    width: 100%;
    background-color: #3498db;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 5px;

`;

export const BoxWrapper = styled.div`
    height: auto;
    padding: 10px;
    /* margin: 10px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: lightgray;
`
export const MessageBox = styled.div`
border-radius: 5px;
    padding: 10px;
    width: 70%;
    display: block;
    position: relative;
    background-color: ${props => props.user ? '#f5f6fa' : '#dff9fb'};
    margin-bottom: 10px;
    left: ${props => props.user ? '0px' : '30%'};
    right: ${props => props.user ? '30%' : '0'};
`;

export default GlobalStyle;