import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Space, Popconfirm, DatePicker,Tag, Card, Modal, Form, Pagination, Row, Col, Divider, Tooltip, Select, Breadcrumb, Descriptions, Timeline, Alert, Empty    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, CustomerServiceOutlined, DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, PlusCircleFilled, RollbackOutlined, SearchOutlined,SendOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { 
  getTicket, TicketCreate, getTicketDetails, ticketReply, operationHelper,
} from '../../actions/ticket';
import history from '../../history';
import { renderInput, renderSelect, renderTextField } from '../../components/form';
import { BoxWrapper, Container, MessageBox, Title, Wrapper } from '../../GlobalStyle';
import { Content } from 'antd/lib/layout/layout';
// import ViewAgent from './view';
const Search = Input.Search;
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const TicketList = ({ 
  getTicket, TicketCreate, getTicketDetails, ticketReply, operationHelper,
 }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);
  const [tid, setTId] = useState(''); 
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [pageOptions, setPageOptions] = useState(null); 
  const [page, setPage] = useState(1); 
  const [subject, setSubject] = useState(null);
  const [state, setState] = useState(null);
  const [ds, setDs] = useState('');
  const [de, setDe] = useState('');
  const [allAcount, setAllAcount] = useState(null);
  const [allSubject, setAllSubject] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [searchedColumn, setSearchedColumn] = useState(null);

  const [form] = Form.useForm();


  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };

  const createOption = (value, label) => {
    const a = '';
    return { value, label };
  };
  const tstatus = [
    {value: 'active', label: 'চলমান টিকেট সমূহ'},
    {value: 'solved', label: 'মীমাংসিত টিকেট সমূহ'},
    {value: '', label: 'সবগুলো টিকেট'},
  ]
  const getData = async () => {
    const helper = await operationHelper();
    let acc = [];
    let sub = [];
    acc = helper?.account?.map((item, key) => createOption(
      item?.id, item?.name
    ))
    sub = helper?.subject?.map((item, key) => createOption(
      item?.name, item?.name
    ))
    setAllAcount(acc);
    setAllSubject(sub);

    const res = await getTicket(page, subject, tid, state, ds, de);
    setData(res?.list);
    setCount(res?.count);
    const boundary = Math.ceil(res?.count / 20);
    
    const pageNumber = [];
      for (let i = 0; i < boundary; i += 1) {
      pageNumber.push({
        value: i + 1,
        label: i + 1, 
      });
    }

    setPageOptions(pageNumber);

  };
  

  useEffect(() => {
    getData();
  }, []);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const handleApiSearch = async (event) => {
    const res = await getTicket(page, subject, tid, state, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };

  const handlePagination = async (e) => {
    const res = await getTicket(e, subject, tid, state, ds, de);
    setData(res?.list);
    setCount(res?.count);
  };

  const handleApiResetSearch = async () => {
    setPage('1');
    setSubject(null);
    setState(null);
    setTId('');
    setDs('');
    setDe('');
    const res = await getTicket('1', '', '', '', '', '');
    setData(res?.list);
    setCount(res?.count);
  };
  
  const handleDate = (date) => {
    if (date) {
      console.log(date[0]?.$d)
      const ds = new Date(date[0]?.$d);
      const de = new Date(date[1].$d);
      setDs((ds.toISOString()).split('T')[0]);
      setDe(de.toISOString().split('T')[0])
    } else {
      setDs(null);
      setDe(null)
      return;
    }
  };
  
  const handleView = async (id) => {
    history.push(`/titcke-details?id=${id}`)
  };

  const createData = (id, subject, status, created_at, action) => {
    return {id, subject, status, created_at, action};
  };

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      align: 'center',
      
    },
    {
      title: 'বিষয়',
      dataIndex: 'subject',
      key: 'subject',
      width: '30%',
      align: 'center',
      ...getColumnSearchProps('subject'),
    },
    {
      title: 'স্টেটাস',
      dataIndex: 'status',
      key: 'status',
      width: '30%',
      align: 'center',
    },
    {
      title: 'সময়',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '30%',
      align: 'center',
    },
    {
      title: 'একশন',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'center',
    },
  ];

  const rows = [
    data
      ? data?.map((item, key) => createData(
        item?.id, 
        item?.account?.name, 
        item?.active ? <Tag color="#f50">চলমান</Tag> : <Tag color="#27ae60">সমাধান হয়েছে</Tag>, 
        item?.created_at ? makeLocalTime(item?.created_at) : null, 
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleView(item?.id)}>বিস্তারিত</Button> &nbsp;
        </>
        ))
      : "",
  ];
  
  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const onFinish = async (values) => {
    const res = await TicketCreate(values);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      form.resetFields();
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  
  return (
    <Content>
      <Breadcrumb
        items={[
          {
            title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
          },
          {
            title: (
              <>
                <span>টিকেট সমুহ</span>
              </>
            ),
          },
          {
            title: (<RollbackOutlined onClick={() => history.goBack()} />),
          },
        ]}
      />
      
      <Row gutter={10}>
        <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
            <Input value={tid} size="middle" placeholder="টিকেট আইডি" allowClear style={{width:'99%'}} onChange={(e)=>setTId(e.target.value)} />
        </Col>
        
         <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
          <Select
            placeholder="সাবজেক্ট সিলেক্ট করুণ"
            size="middle"
            allowClear
            style={{width:'99%'}}
            onChange={(e)=> setSubject(e)}
            value={subject}
          >
            {allSubject?.map(item => (
                <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </Col>

        <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
              <RangePicker
                onChange={handleDate}
                format="YYYY/MM/DD"
                style={{ width: "99%" }}
              />
        </Col>
       
        <Col style={{margin: '5px 0px'}} xs={12} sm={12} md={8} lg={4} xl={4}>
          <Select
            placeholder="স্টেটাস সিলেক্ট করুণ"
            size="middle"
            allowClear
            style={{width:'99%'}} onChange={(e)=> setState(e)}
            value={state}
          >
              {tstatus?.map(item => (
                  <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        </Col>
        

        {/* <Col xs={12} sm={12} md={3} lg={2} xl={2}> */}
            
                <Button  style={{margin: '5px 5px'}} size="middle" type="primary" onClick={handleApiSearch} icon={<SearchOutlined />}>খুজুন </Button>
        
        {/* </Col> */}

        {/* <Col xs={12} sm={12} md={5} lg={3} xl={3}> */}
            
                <Button  style={{margin: '5px 5px'}} size="middle" type="primary" danger onClick={handleApiResetSearch} icon={<DeleteOutlined />}>ফিল্টার মুছুন</Button>
            
        {/* </Col> */}
          {/* <br />
          <br /> */}
        {/* <Col xs={12} sm={12} md={8} lg={3} xl={3}> */}
          <Button style={{margin: '5px 5px'}} size="middle" type="primary" icon={<PlusCircleFilled />} onClick = { showModal }>নতুন তৈরি</Button>
        {/* </Col> */}

      </Row>
      <br />
      <Table size='small' columns={columns} tableLayout='auto' pagination={false} dataSource={rows[0]} sticky/>
      <br />
      {count ? <Pagination pageSize="20" defaultCurrent={page} total={count} onChange={(e) => { handlePagination(e); }} /> : null}
      
      
      <Modal 
        title="নতুন টিকেট" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={1000}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
             
              {renderSelect('subject', 'সাব্জেক্ট', [
                  { required: false, message: 'This field is required!' },
                ], "", allSubject, 'single', 'small')
              }
              {renderSelect('account', 'একাউন্ট', [
                  { required: true, message: 'This field is required!' },
                ], "", allAcount, 'single', 'small')
              }
              {renderTextField('message', 'মেসেজ', [
                  { required: true, message: 'This field is required!' },
                ], 'small')
              }
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
    </Content>
  );
};

export default connect(null, { 
  getTicket, TicketCreate, getTicketDetails, ticketReply, operationHelper,
 })(TicketList);
