import React,{  useEffect, useState } from "react";
import { connect } from "react-redux";
import {Route } from "react-router-dom";
import Spinner from "../components/spinner";
import history from "../history";
import NetworkError from "./NetworkError";
import DonePage from "./DonePage";
import Enterprise from "../page/enterprice";
import notFound from "./notFound";
import Dashboard from "../page/dashboard";
import DepositeList from "../page/deposite";
import Setting from "../page/user/Setting";
import CreateReport from "../page/report";
import LoanGiven from "../page/account/LoanGiven";
import CreateLoan from "../page/account/CreateLoan";

import TicketList from "../page/ticket";
import ticketDetails from "../page/ticket/ticketDetails";
import productList from "../page/enterprice/productList";
import UserDetail from "../page/dashboard/user";
import LoanList from "../page/loan";
import loanDetails from "../page/loan/loanDetails";
import depositeDetails from "../page/deposite/depositeDetails";
import DepositeMoney from "../page/deposite/depositeMoney";
import withdrawDetails from "../page/withdraw/withdrawDetails";
import withdrawMoney from "../page/withdraw/withdrawMoney";
import withdrawList from "../page/withdraw";

import ReportList from "../page/report";


const Switcher = ({loader}) => {
    const [access, setAccess] = useState(null)  
  useEffect(() => {
    window.scrollTo(0, 0)
    if(!localStorage.getItem("token") ||
    localStorage.getItem("token") === null){
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem('access')))
    
  }, [])
  return (
    <>
    {loader ? <Spinner /> : null} 
      <switch>
        <Route path="/dashboard" component={Dashboard} />

        <Route path="/user-details" component={UserDetail} />

        <Route path="/tickets" component={TicketList} />
        <Route path="/titcke-details" component={ticketDetails} />

        <Route path="/" exact component={Dashboard}/>
        <Route path="/notFound" exact component={notFound}/>
        <Route path="/networkError" exact component={NetworkError}/>
        {/* MessAGE */}
       
        <Route path="/done"  component={DonePage}/>
        <Route path="/Enterprise"  component={Enterprise}/>
        <Route path="/loan-list"  component={LoanList}/>
        <Route path="/loan-details"  component={loanDetails}/>
        <Route path="/product-list"  component={productList}/>

        <Route path="/report"  component={ReportList}/>

        <Route path="/deposite"  component={DepositeList}/>
        <Route path="/deposite-money"  component={DepositeMoney}/>
        <Route path="/deposite-details"  component={depositeDetails}/>
        <Route path="/withdraw"  component={withdrawList}/>
        <Route path="/withdraw-money"  component={withdrawMoney}/>
        <Route path="/withdraw-details"  component={withdrawDetails}/>
        <Route path="/setting"  component={Setting}/>
        <Route path="/loangiven"  component={LoanGiven}/>
        <Route path="/createloan"  component={CreateLoan}/>
        
        
      </switch>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loading,
    success: state.message.success,
    error: state.message.error,
    validationError: state.message.validationError,
  };
};
export default connect(mapStateToProps)(Switcher);
