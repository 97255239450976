import React, { useEffect, useState } from 'react';
import { Button, Tag, Card, Form, Row, Col, Tooltip, Breadcrumb, Descriptions, Alert, Empty    } from 'antd';
import { HomeOutlined, RollbackOutlined, SendOutlined, } from '@ant-design/icons';
import { connect } from 'react-redux';
import { 
  getTicketDetails, ticketReply,
} from '../../actions/ticket';
import history from '../../history';
import { renderTextField } from '../../components/form';
import { BoxWrapper, Container, MessageBox, Title, Wrapper } from '../../GlobalStyle';
import { Content } from 'antd/lib/layout/layout';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const TicketDetails = ({ 
  getTicketDetails, ticketReply, location,
 }) => {
  const [details, setDetails] = useState('');
  const [replayForm] = Form.useForm();

  const getData = async () => {
    const id = new URLSearchParams(location.search).get("id");
    const res = await getTicketDetails(id)
    setDetails(res);
  };
  
  useEffect(() => {
    getData();
  }, []);


  const onFinishReplayForm = async (data) => {
    const res = await ticketReply(details?.id, data );
    if(res ===  201){
      const res = await getTicketDetails(details?.id)
      setDetails(res);
      replayForm.resetFields();
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  
  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };
  return (
    <Content>
      <Breadcrumb
        items={[
          {
            title: <HomeOutlined onClick={() => history.push('/dashboard')} />,
          },
          {
            title: (
              <>
                <span>টিকেটের বিবিরণ</span>
              </>
            ),
          },
          {
            title: (<RollbackOutlined onClick={() => history.goBack()} />),
          },
        ]}
      />

      <Wrapper>
        <h2 style={{ margin: '3px 0px' }}>সাবজেক্ট : {details.subject}</h2>
        <Tag color="#2db7f5">টিকেট আইডি <strong style={{ fontSize: '20px'}}>{details?.id}</strong></Tag>
        {details?.active ? <Tag color="#f50">চলমান</Tag> : null}
        {!details?.active ? <Tag color="#27ae60">সমাধান হয়েছে</Tag> : null}

        <Tag color="#cd2df5" >{details?.created_at ? makeLocalTime(details?.created_at) : null}</Tag>
        <br />
        <br />
        <Row gutter={24}>
          <Col span={24}>
            
            {details?.account ?
            <>
              <Title>একাউন্ট এর বিবিরণ</Title>
              <Descriptions title="" size="small">
                
                <Descriptions.Item label="একাউন্টের নাম"><strong>{details?.account?.name}</strong></Descriptions.Item>
                <Descriptions.Item label="একাউন্টের ইমেইন"><strong>{details?.account?.email}</strong></Descriptions.Item>
              </Descriptions>
            </>
            : null}
          </Col>
          <Col span={24}> <br /> </Col>
          <Col span={24}  style={{ maxHeight: '500px', overflow: 'scroll'}}>  
            {details?.logs ? <Title>মেসেজিং</Title> : null}
            <br />
            {details?.logs ? 
              <BoxWrapper >
                
                {details?.logs.map((item,id) => (
                <MessageBox user = {item?.is_admin}>
                      <Card>
                        {!item?.is_admin ? <span style={{color: '#000',fontWeight: 'bold'}}>{localStorage.getItem('name')} (Client)</span> : <span style={{color: '#000',fontWeight: 'bold'}}>{item?.enterprise_user?.name} (Support)</span> }
                        
                          <p>
                            {item?.message}
                          </p>
                          <Tooltip title={makeLocalTime(item.created_at).split(',')[0]}>
                            <span style={{color: '#000'}}>{makeLocalTime(item.created_at).split(',')[1]}</span>
                          </Tooltip>
                      </Card>
                </MessageBox>
                ))}
                {details?.active ?
                  <Form
                  {...layout}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinishReplayForm}
                  onFinishFailed={onFinishFailed}
                  form={replayForm}
                  requiredMark={false}
                >
                  {renderTextField('message', 'মেসেজ', [
                      { required: true, message: 'This field is required!' },
                    ], 'small')
                  }
                  
                  <Form.Item {...tailLayout}> 
                    <Button type="primary" htmlType="submit" icon={<SendOutlined />}>
                      রিপ্লাই দিন
                    </Button>
                  </Form.Item>
                        
                </Form>
                :
                <Alert message="This ticket is closed.......!" type="info" />
                }
              </BoxWrapper>
              :
              <Empty />
            }
          </Col>
            {/* {details?.active === 'active' ?
              <Col span={12}>
                <Title>রিপ্লাই দিন</Title>
                <br />
                <Form
                  {...layout}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinishReplayForm}
                  onFinishFailed={onFinishFailed}
                  form={replayForm}
                >
                  {renderTextField('message', 'মেসেজ', [
                      { required: true, message: 'This field is required!' },
                    ], 'small')
                  }
                  
                  <Form.Item {...tailLayout}> 
                    <Button type="primary" htmlType="submit">
                      জমা দিন
                    </Button>
                  </Form.Item>
                        
                </Form>
              </Col>
            : null}
            

            <Col span={details?.active === 'active' ? 12 : 24}>
              
            <Title>সকল কার্যাবলি</Title>
              <br />
              <Timeline style={{ padding: '2px 0px', maxHeight: '180px', overflow: 'auto' }}>
                {details?.logs?.map(item => (
                  <Timeline.Item>
                    <p>{item?.enterprise_user?.name}</p>
                    <p>{item?.message}</p>
                    <p style={{ fontSize: '10px', fontWeight: 'bold' }}>{makeLocalTime(item?.created_at)}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col> */}
        </Row>
      </Wrapper>
    </Content>
  );
};

export default connect(null, { 
  getTicketDetails, ticketReply,
 })(TicketDetails);
