import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  GET_USERS,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";

export const createUsers = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const personal_info = {
    date_of_birth: data.date_of_birth,
    gender: data.gender,
    permanent_address: data.permanent_address,
    present_address: data.present_address,
    emergency_number: data.emergency_number,
  };
  const object = {
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    password: data.password,
    authenticated: true,
    user_id: data.user_id,
    role: data.role,

    personal_info,
  };

  try {
    const response = await api(localStorage.getItem("token")).post(
      "/user-management/create-user",
      { ...object }
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getUsers = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/user-management/get-users"
    );

    dispatch({ type: LOADED });
    return response.data.users;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const getUserDetails = (id) => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
      
      const response = await api(localStorage.getItem("token")).get(`/user-management/user/detail?user=${id}`);
      
      if(response.status === 201 ) {
        dispatch({ type: LOADED });
        return response.data.details;
      }
    }
    catch( err ){
      
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: AUTH_ERROR, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          if(err.message == 'Network Error')
          {
              history.push('/networkError')
              return
          }
          dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
       
      }
  }

export const getUsersById = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/user-management/user-detail?user=${id}`
    );

    dispatch({ type: LOADED });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const personal_info = {
    date_of_birth: data.date_of_birth,
    gender: data.gender,
    permanent_address: data.permanent_address,
    present_address: data.present_address,
    emergency_number: data.emergency_number,
  };

  const object = {
    remark: data.remark,
    name: data.name,
    email: data.email,
    mobile_number: data.mobile_number,
    password: data.password,
    authenticated: true,
    user: data.user_id,
    role: data.role,

    personal_info,
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/user-management/update-user",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User is updated succesfully",
      });

      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const updateUserPassword = (user,data) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    user: user,
    password: data.password,
    confirm_password: data.confirm_password,
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/user-management/update-user-password",
      { ...object }
    );


    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User Password is updated succesfully",
      });
     return response.status
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const deleteUser = (user, {remark}) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const response = await axios(
      "https://api.bahonexpress.com/archon/v1/user-management/delete-user",
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          user: user,
          remark: remark,
        },
        method: "delete",
      }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User is deleted succesfully",
      });

      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const updateAuth = (user, auth) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    user: user,
    authenticated: auth,
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/user-management/auth-user",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "User authentication is updated succesfully",
      });

      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if(err.message == 'Network Error')
    {
        history.push('/networkError')
        return
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
