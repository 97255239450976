import React from 'react'
import { Form, Checkbox } from 'antd';
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
const CheckBox = ({label,name, value, rules, ...rest}) => {
    return ( 
       
        <Form.Item
            {...tailLayout}
            name={name}
            rules={rules}
            valuePropName="checked" 
            >
            <Checkbox size="large" name={name} value={value} {...rest}>{label}</Checkbox>
        </Form.Item>
     );
}
 
export default CheckBox;