import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col, Card, Button, Input, Table } from "antd";
import {
  DownloadOutlined

} from "@ant-design/icons";
import { connect } from "react-redux";
import { fileDownloader } from "../../actions/deposite";

const DepositeDetails = ({ data, fileDownloader }) => {


  useEffect(async () => {
    window.scrollTo(0, 0)
  }, []);


  const makeLocalTime = (date) => {
    let hours = date?.split("T")[1].split(":")[0];
    let minutes = date?.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours === 12) {
      am = false;
    } else if (hours === 0) {
      hours = 12;
    }
    return (
      date?.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };
  const dataSet = [
    {
      title: 'সর্বোমোট বিক্রি',
      value: data?.sale,
      file: data?.sale_fl,
      detail: data?.detail,
    },
    {
      title: 'সংগ্রহ (বিক্রয়)',
      value: data?.received_from_sale,
      file: data?.received_from_sale_fl,
    },
    {
      title: 'অনুদান (টাকা)',
      value: data?.grant_financing,
      file: data?.grant_financing_fl,
    },
    {
      title: 'পাওনা (বিক্রয়)',
      value: data?.receivable,
      file: data?.receivable_fl,
    },
    {
      title: 'নিজের জমা',
      value: data?.owner_equity,
      file: data?.owner_equity_fl,
    },
    {
      title: 'অনুদানকৃত সম্পদ',
      value: data?.in_kind,
      file: data?.in_kind_fl,
    },
    {
      title: 'অনুদানকৃত মালামাল',
      value: data?.grant_inventory,
      file: data?.grant_inventory_fl,
    },
    {
      title: 'অন্যান্য',
      value: data?.other,
      file: data?.other_fl,
    },
    {
      title: 'নগত',
      value: data?.cash,
      file: data?.cash_fl,
    },
    {
      title: 'মোবাইল ব্যাংকিং',
      value: data?.mfs,
      file: data?.mfs_fl,
    },
    {
      title: 'ব্যাংক',
      value: data?.bank,
      file: data?.bank_fl,
    },
    {
      title: 'ইনভেন্টরি',
      value: data?.inventory,
      file: data?.inventory_fl,
    },
  ]
  return (
    <Content
    >

      <p style={{ textAlign: 'center' }}>{data?.transaction_date ? makeLocalTime(data?.transaction_date) : null}</p>
      <br />
      <br />
      <Row gutter={10}>
        {dataSet?.map( (item,id) => (
          item?.detail ?
            <Col xs={24} md={24} key={id}>
              <Card bordered={true} style={{  marginBottom: '10px' }}>
                <Row gutter={10}>
                  <Col xs={24} md={8} style={{  marginBottom: '10px' }}>
                    <Statistic
                      title={<strong style={{ fontSize: '10px', color: '#000'}}>{item?.title}</strong>}
                      value={item?.value}
                      precision={2}
                      valueStyle={{
                        color: '#3f8600',
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                      suffix="৳"
                    />
                      {item?.file ?
                        <Button size="small"
                          onClick={() => fileDownloader(item?.file)}
                          type="primary"
                          style={{ backgroundColor: '#019fb8', fontSize: '10px' }} icon={<DownloadOutlined />}>
                              ডাউনলোড
                        </Button>
                      : 
                      <Button size="small" disabled style={{ fontSize: '10px' }} icon={<DownloadOutlined />}>
                        ডাটা নেই
                      </Button>}
                  </Col>
                  
                  <Col xs={24} md={16}>
                    <h5 style={{ borderBottom: '1px solid grey' }}>প্রোডাক্ট সমূহ</h5>
                    {item?.detail?.map((product, id) => (
                      <>
                        <p>{item?.detail[id ]}</p>
                      </>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          :
            <Col xs={12} md={6}>
              <Card bordered={true} style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <Statistic
                  title={<strong style={{ fontSize: '10px', color: '#000'}}>{item?.title}</strong>}
                  value={item?.value}
                  precision={2}
                  valueStyle={{
                    color: '#3f8600',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                  suffix="৳"
                />
                  {item?.file ?
                    <Button size="small"
                      onClick={() => fileDownloader(item?.file)}
                      type="primary"
                      style={{ backgroundColor: '#019fb8', fontSize: '10px' }} icon={<DownloadOutlined />}>
                          ডাউনলোড
                    </Button>
                  : 
                  <Button size="small" disabled style={{ fontSize: '10px' }} icon={<DownloadOutlined />}>
                        ডাটা নেই
                  </Button>}
              </Card>
            </Col>
          
        ))}
      </Row>
      {/* <table style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', }}>
        <tr>
          <td style={{ margin: '10px' }} >সর্বোমোট বিক্রি: </td>
          <td style={{ margin: '10px' }}> {data?.sale} ৳ </td>
          {data?.sale_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.sale_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >বিক্রয় থেকে পাওনা: </td>
          <td style={{ margin: '10px' }}> {data?.receivable_from_sale} ৳ </td>
          
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >বিক্রয় থেকে সংগ্রহ: </td>
          <td style={{ margin: '10px' }}> {data?.received_from_sale} ৳ </td>
          {data?.received_from_sale_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.received_from_sale_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >অনুদান (টাকা) : </td>
          <td style={{ margin: '10px' }}> {data?.grant_financing} ৳ </td>
          {data?.grant_financing_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.grant_financing_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >পাওনা : </td>
          <td style={{ margin: '10px' }}> {data?.receivable} ৳ </td>
          {data?.receivable_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.receivable_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >নিজের জমা : </td>
          <td style={{ margin: '10px' }}> {data?.owner_equity} ৳ </td>
          {data?.owner_equity_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.owner_equity_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >অনুদানকৃত সম্পদ : </td>
          <td style={{ margin: '10px' }}> {data?.in_kind} ৳ </td>
          {data?.in_kind_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.in_kind_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >ক্যাশ : </td>
          <td style={{ margin: '10px' }}> {data?.cash} ৳ </td>
          {data?.cash_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.cash_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >মোবাইল ব্যাংকিং : </td>
          <td style={{ margin: '10px' }}> {data?.mfs} ৳ </td>
          {data?.mfs_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.mfs_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >ব্যাংক : </td>
          <td style={{ margin: '10px' }}> {data?.bank} ৳ </td>
          {data?.bank_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.bank_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >ইনভেন্টরি : </td>
          <td style={{ margin: '10px' }}> {data?.inventory} ৳ </td>
          
        </tr>
        <tr>
          <td style={{ margin: '10px' }} >অন্যান্য : </td>
          <td style={{ margin: '10px' }}> {data?.other} ৳ </td>
          {data?.other_fl ?
            <td style={{ margin: '10px' }}>
              <Button size="small"
                onClick={() => fileDownloader(data?.other_fl)}
                type="primary"
                style={{ backgroundColor: '#019fb8', margin: '10px' }}>
                  <DownloadOutlined />
              </Button>
            </td> 
          : null}
        </tr>

      </table> */}
   




    </Content>

  );
};

export default connect( null, { fileDownloader }) (DepositeDetails);

