import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Statistic, Row, Col,Card,Button } from "antd";
import {
  LikeOutlined,InfoCircleOutlined
 
} from "@ant-design/icons";
import { connect } from "react-redux";
import history from "../../history";

import { getUsers } from "../../actions/user";

import { CardTitle, SubTitle, TitleH1 } from "../../Style";

import CreateLoan from "./CreateLoan";

const LoanGiven = ({getUsers}) => {
  const [access, setAccess] = useState('');
  const [createSubOrg, setCreateSubOrg] = useState(false);
  const [data, setData] = useState([]);
  useEffect(async() => {
    window.scrollTo(0, 0)
    // const res = await getUsers();
    // setData(res);
  }, []);
  // const handleRefresh = async () => {
  //   const res = await getUsers();
  //   setData(res);
  // };

  const createorg = () => {
    setCreateSubOrg(true);
  };
  // console.log(access)
  return (
 <Content
 >  
 
 <SubTitle style={{textAlign:'center'}} > ঋণ ম্যানেজমেন্ট </SubTitle>
 <Button
              key="1"
              type="primary"
              style={{float:'right'}}
              
              onClick={() => createorg()}
            >
             নতুন ঋণ
            </Button>
            {createSubOrg? 
      <CreateLoan
        title="নতুন ঋণ"
        visible={createSubOrg}
        cancel={() => setCreateSubOrg(false)}
        ok={() => setCreateSubOrg(false)}
        // api={createUsers}
        // refresh={handleRefresh}
      />:null}
      <br/>
      <br/>

 
    <Row
        gutter={20}
       
        
      >
        <Col xs={24} lg={12} md={12} sm={12} xl={12} style={{ marginTop: "10px"}}>
        <Card hoverable s>
          <CardTitle>প্রদেয় পরিমান </CardTitle>

           <CardTitle >৫০০০ টাকা</CardTitle>

          <hr ></hr>
          </Card>
          
        </Col>

        <Col xs={24} lg={12} md={12} sm={12} style={{ marginTop: "10px" }}>
        <Card hoverable >
          <CardTitle>প্রাপ্য পরিমাণ</CardTitle>

           <CardTitle >১৫০০০ টাকা</CardTitle>

          <hr ></hr>
          </Card>
          
        </Col>
        

        
      </Row>
    <Row
        gutter={20}
        
        
      >
        <Col xs={24} lg={24} md={24} sm={24} xl={24} style={{ marginTop: "10px"}}>
          <Card hoverable >
          <Row  gutter={20}> 
          <Col xs={24} lg={24} md={24} sm={24} xl={24} >ঋণ আই ডি :4f550824-7ff8-4492-acd5-954539da91b0 </Col> 
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>ঋণ স্ট্যাটাস :Pending  </Col>  
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>পরিমান: 10000 ৳ </Col>
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>ধরন: Taken </Col>
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>তারিখ: 2023-03-13 05:36 PMView  </Col><br/>
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}><Button
              key="1"
              type="primary"
              style={{width:'100%',}}
              
              onClick={() => createorg()}
            >
             বিস্তারিত
            </Button> </Col>
          
          </Row>
          
          </Card>
          
        </Col>

        <Col xs={24} lg={24} md={24} sm={24} xl={24} style={{ marginTop: "10px"}}>
          <Card hoverable >
          <Row  gutter={20}> 
          <Col xs={24} lg={24} md={24} sm={24} xl={24} >ঋণ আই ডি :4f550824-7ff8-4492-acd5-954539da91b0 </Col> 
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>ঋণ স্ট্যাটাস :Pending  </Col>  
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>পরিমান: 10000 ৳ </Col>
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>ধরন: Taken </Col>
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}>তারিখ: 2023-03-13 05:36 PMView  </Col><br/>
          <Col  xs={24} lg={24} md={24} sm={24} xl={24}><Button
              key="1"
              type="primary"
              style={{width:'100%',}}
              
              onClick={() => createorg()}
            >
             বিস্তারিত
            </Button> </Col>
          
          </Row>
          
          </Card>
          
        </Col>
        

        
      </Row>
      
    </Content>
   
  );
};
export default connect(null, {
    getUsers
  })(LoanGiven);

